import React, {useEffect, useState} from 'react';
import {CallerOverViewProps} from './CallerOverView.props';
import {InfoPanel} from '../../molecules/InfoPanel';
import './CallerOverView.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {InputLabel} from '../../molecules/InputLabel';

export const CallerOverView: React.FC<CallerOverViewProps> = ({
  callerOverViewData
}
) => {
  // const [isEdit, setIsEdit] = useState<boolean>(true);
  const [sex, setSex] = useState<string>(callerOverViewData.sex || '');
  const [ethnicity, setEthnicity] = useState<string>(callerOverViewData.ethnicity || '');
  const [hairColor, setHairColor] = useState<string>(callerOverViewData.hairColour || '');
  const [height, setHeight] = useState<string>(callerOverViewData.height || '');
  const [weight, setWeight] = useState<string>(callerOverViewData.weight || '');
  const [livingStatus, setLivingStatus] = useState<string>(callerOverViewData.livingStatus || '');

  const onChangeInput = (event: any, type: string) => {
    switch (type) {
    case CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.SEX:
      callerOverViewData.sex = event.target.value;
      setSex(event.target.value);
      break;
    case CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.ETHNICITY:
      callerOverViewData.ethnicity = event.target.value;
      setEthnicity(event.target.value);
      break;
    case CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.HAIR_COLOR:
      callerOverViewData.hairColour = event.target.value;
      setHairColor(event.target.value);
      break;
    case CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.HEIGHT:
      callerOverViewData.height = event.target.value;
      setLivingStatus(event.target.value);
      break;
    case CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.WEIGHT:
      callerOverViewData.weight = event.target.value;
      setWeight(event.target.value);
      break;
    case CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.LIVING_STATUS:
      callerOverViewData.livingStatus = event.target.value;
      setHeight(event.target.value);
      break;

    default:
      break;
    }
    console.log(callerOverViewData);
    // use callerOverViewData to submit data
  };

  useEffect(() => {
    setSex(callerOverViewData.sex??'');
    setEthnicity(callerOverViewData.ethnicity??'');
    setHairColor(callerOverViewData.hairColour??'');
    setHeight(callerOverViewData.height??'');
    setWeight(callerOverViewData.weight??'');
    setLivingStatus(callerOverViewData.livingStatus??'');
  }, [callerOverViewData]);

  return (
    <div className="caller-overview-style">
      <InfoPanel height="36px" width="100%" title={CONSTANTS.CALLERS.OVERVIEW} />
      <div className="caller-overview">
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_OVERVIEW.LABEL.SEX}
          value={sex}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.SEX)}
          widthInput="calc(100% - 116px)"
          widthInputLabel="100px"
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_OVERVIEW.LABEL.ETHNICITY}
          value={ethnicity}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.ETHNICITY)}
          widthInput="calc(100% - 116px)"
          widthInputLabel="100px"
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_OVERVIEW.LABEL.HEIGHT}
          value={height}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.HEIGHT)}
          widthInput="calc(100% - 116px)"
          widthInputLabel="100px"
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_OVERVIEW.LABEL.WEIGHT}
          value={weight}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.WEIGHT)}
          widthInput="calc(100% - 116px)"
          widthInputLabel="100px"
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_OVERVIEW.LABEL.HAIR_COLOR}
          value={hairColor}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.HAIR_COLOR)}
          widthInput="calc(100% - 116px)"
          widthInputLabel="100px"
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_OVERVIEW.LABEL.LIVING_STATUS}
          value={livingStatus}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_OVERVIEW.TYPE_CHANGE.LIVING_STATUS)}
          widthInput="calc(100% - 116px)"
          widthInputLabel="100px"
        />
      </div>
    </div>
  );
};