import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {HomeProps} from './Home.props';
import './Home.styles.css';
import {MapControl, MapMarkerTracking, MapType} from '../../components/layouts/MapControl';
import {InputField, InputFieldVariant} from '../../components/atoms/InputField';
import {Colors} from '../../configs/colors';
import {ReactComponent as Search} from '../../assets/images/search-icon.svg';
import {CallsTable, InteractionsData} from '../../components/organisms/CallsTable';
import {AnswerDialog} from '../../components/molecules/AnswerDialog';
import {parseAgeAttribute, parseInteractionIdAttribute, parseSubscriberIdAttribute} from '../../utils/parsers';
import {CONSTANTS} from '../../constants/constants';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {
  setAgentStatus,
  setUserTaskAccept,
  setUserTaskReject,
  userTaskSelector
} from '../../redux/userTaskSlice';
import {
  interactionStatisticsSelector,
  interactionStatisticsGetCallStatistics,
  interactionStatisticsActiveInteractions,
  interactionStatisticsOpenInteractions
} from '../../redux/interactionStatisticsSlice';
import {getCurrentIsoDate} from '../../utils/dateTimeHelper';
import {userLocationSelector} from '../../redux/userLocationSlice';
import {SearchLocation} from '../../components/molecules/SearchLocationInput';
import {MapLocation} from '../../components/atoms/Map';
import {setSubscriberSelectedObjectId} from '../../redux/subscriberSearchSlice';
import {setSelectedInteractionDetailsObjectId, setInteractionDetailsClear, setSelectedInteractionDetailsObjectClear} from '../../redux/interactionDetailsSlice';
import {Button, ButtonVariant} from '../../components/atoms/Button';
import {Text} from '../../components/atoms/Text';
import {ReactComponent as UserTalk}  from '../../assets/images/icon-user-talk.svg';
import {setVideoCallConnecting} from '../../redux/videoCallSlice';
import {ReactComponent as ImgIncomingCall} from '../../assets/images/icon-phone-call.svg';

export const Home: React.FC<HomeProps> = () => {
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const userLocation = useAppSelector(userLocationSelector);
  const navigate = useNavigate();
  const interactionsStatisticSelect = useAppSelector(interactionStatisticsSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showIncomingCall, setShowIncomingCall] = useState<boolean>(false);
  const [isChild, setIsChild] = useState(false);
  const [callerType, setCallerType] = useState<string>(CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL);
  const [chatCount, setChatInteractionCount] = useState<number>(0);
  const [videoCallCount, setVideoCallInteractionCount] = useState<number>(0);
  const [cctvCount, setCctvInteractionCount] = useState<number>(0);
  const [coordinate, setCoordinate] = useState<MapLocation | undefined>(undefined);
  const [activeInteractions, setActiveInteractions] = useState<InteractionsData[]>([]);
  const [openInteractions, setOpenInteractions] = useState<InteractionsData[]>([]);
  const [currentAgentStatus, setCurrentAgentStatus] = useState<string>();

  useEffect(() => {
    dispatch(interactionStatisticsActiveInteractions());
    console.log('^^^^^WSS-Load: dispatch interactionStatisticsActiveInteractions');
    dispatch(interactionStatisticsOpenInteractions());
    console.log('^^^^^WSS-Load: dispatch interactionStatisticsOpenInteractions');
    dispatch(interactionStatisticsGetCallStatistics(getCurrentIsoDate()));
    console.log('^^^^^WSS-Load: dispatch interactionStatisticsGetCallStatistics');
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (userTask.agent) {
      setCurrentAgentStatus(userTask.agent.getState().name);

      userTask.agent.onStateChange((states: any) => {
        setCurrentAgentStatus(states['newState']);
      });
    }
  }, [userTask.agent]);

  useEffect(() => {
    if (userTask.isIncomming) {
      setShowIncomingCall(true);
      if (userTask.attributes) {
        const interactionType = userTask.attributes.InteractionType.value;
        setCallerType(interactionType);
        const age = parseAgeAttribute(userTask.attributes);
        setIsChild(age < 15);
      }
    } else {
      setShowIncomingCall(false);
    }

    if (userTask.isConnected) {
      setShowIncomingCall(false);
    }
    if (!userTask.isConnected && !userTask.isIncomming) {
      dispatch(setInteractionDetailsClear());
    }
  }, [userTask.isIncomming, userTask.isConnected]);

  useEffect(() => {
    if(interactionsStatisticSelect.data){
      setChatInteractionCount(interactionsStatisticSelect.data.chatCount);
      setVideoCallInteractionCount(interactionsStatisticSelect.data.videoCallCount);
      setCctvInteractionCount(interactionsStatisticSelect.data.cctvCount);
      setActiveInteractions(interactionsStatisticSelect.data.activeInteractions);
      setOpenInteractions(interactionsStatisticSelect.data.openInteractions);
    }
  }, [interactionsStatisticSelect.data]);

  const onAcceptContact = () => {
    setShowIncomingCall(false);
    dispatch(setUserTaskAccept());
    dispatch(setVideoCallConnecting({isLoading: true}));
    localStorage.setItem('subscriberId', parseSubscriberIdAttribute(userTask.attributes));
    localStorage.setItem('interactionId', parseInteractionIdAttribute(userTask.attributes));
  };

  const onRejectContact = () => {
    setShowIncomingCall(false);
    dispatch(setUserTaskReject());
  };

  const onChangeLocation = (data: SearchLocation) => {
    const position = {
      lat: data.lat,
      lng: data.lng
    };
    setCoordinate(position);
  };

  const rowClickEvent = (event: any, call: InteractionsData) => {
    // handle something
  };

  const rowDoubleClickEvent = (event: any, call: InteractionsData) => {
    if (call.interactionId) {
      localStorage.setItem('interactionId', call.interactionId);
      dispatch(setSelectedInteractionDetailsObjectId(call.interactionId));
    }
    localStorage.setItem('subscriberId', call.subscriberId);
    dispatch(setSubscriberSelectedObjectId(call.subscriberId));
    navigate('/callers');
  };

  const ondblclickDot = () => {
    if (showIncomingCall) {
      onAcceptContact();
    } else {
      dispatch(setSelectedInteractionDetailsObjectClear());
      dispatch(setSubscriberSelectedObjectId(parseSubscriberIdAttribute(userTask.attributes)));
      navigate('/callers');
    }
  };

  const mapMarkerTracking: MapMarkerTracking | null = (userTask.isIncomming || userTask.isConnected) && userLocation && userLocation.data
    ?
    {
      position: {lat: userLocation.data.latitude, lng: userLocation.data?.longitude},
      callerType: userTask.attributes?.InteractionType.value || '',
    }
    : null;

  const hasActiveInteractions = activeInteractions?.length > 0;
  const showActiveButton = currentAgentStatus !== CONSTANTS.RC_OPERATOR_Status.ACW && currentAgentStatus !== CONSTANTS.RC_OPERATOR_Status.Available && currentAgentStatus !== CONSTANTS.RC_OPERATOR_Status.OnInteraction && hasActiveInteractions;

  let filteredActiveInteractions = activeInteractions;
  let filteredOpenInteractions = openInteractions;

  if (searchValue) {
    const searchVal = searchValue.toLocaleLowerCase();
    filteredActiveInteractions = activeInteractions.filter(x => x.contact.toLocaleLowerCase().includes(searchVal) || x.from.toLocaleLowerCase().includes(searchVal));
    filteredOpenInteractions = openInteractions.filter(x => x.contact.toLocaleLowerCase().includes(searchVal) || x.from.toLocaleLowerCase().includes(searchVal));
  }

  return (
    <div className="home-style">
      <div className="home-body">
        <div className="home-map">
          <MapControl
            type={MapType.Small}
            zoomLevel={CONSTANTS.MAP_ZOOMS.Default}
            onChangeLocation={onChangeLocation}
            coordinate={coordinate}
            noOfCctvItems={cctvCount}
            noOfChatItems={chatCount}
            noOfVideoItems={videoCallCount}
            heightMap="calc(100vh - 120px)"
            mapMarkerTracking={mapMarkerTracking ? [mapMarkerTracking] : []}
            onDoubleClickDot={ondblclickDot}
          />
          {showIncomingCall &&
            <div className="home-button-container">
              <AnswerDialog 
                callerType={callerType} 
                isChild={isChild} 
                onAccept={onAcceptContact} 
                onReject={onRejectContact} 
              />
            </div>
          }
          {showActiveButton && <div className="home-button-container">
            <div className="message-button-wrap">
              <ImgIncomingCall stroke={Colors.RCCritical}/>
              <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.RCCritical}>Incoming call...</Text>
            </div>
            <Button
              variant={ButtonVariant.Primary}
              width="250px"
              height="56px"
              margin="0 0 0 16px"
              color={Colors.RCSuccess}
              textColor={Colors.RCSuccess}
              onClick={() => dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.Available))}>
              <UserTalk stroke={Colors.LSWhite}/>
              <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.LSWhite}>{'Set to available'}</Text>
            </Button>
          </div>
          }
        </div>
        <div className="home-content">
          {/* <CallerStatistic activeAgents={fakeActiveAgents} operatorStatus={fakeOperatorStatus} /> */}
          <div className="search-table">
            <InputField
              variant={InputFieldVariant.Labelled}
              width="80%"
              border="1px solid #D6D8D9"
              boxShadow="0px 1px 2px rgba(0, 0, 0, 0.1)"
              label="Filter by name or number"
              labelColor={Colors.NeutralGrey2}
              type="text"
              alt=""
              placeholder="Filter by name or number"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              Icon={Search}
            />
          </div>
          <CallsTable
            activeInteractions={filteredActiveInteractions}
            openInteractions={filteredOpenInteractions}
            rowClicked={rowClickEvent}
            doubleRowClick={rowDoubleClickEvent}
          />
        </div>
      </div>
      {/* <AudioControls isPlaying={true}/> */}
    </div>
  );
};
