/* eslint-disable @typescript-eslint/no-explicit-any */
import {CONSTANTS} from '../constants/constants';
import {HttpMethod, httpClient} from './httpClient';

export const getSearchByKey = (searchKey: string): Promise<any> => {
  return httpClient(`/subscriber/subscribers/search?searchtext=${searchKey}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const getSubscriberById = (id: string): Promise<any> => {
  return httpClient(`/subscriber/subscribers?id=${id}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const getAllSearch = (): Promise<any> => {
  return httpClient('/subscriber/search', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const createOneSearch = (data: any): Promise<any> => {
  return httpClient('/subscriber/search', HttpMethod.Post, data, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const updateOneSearch = (data: any): Promise<any> => {
  return httpClient(`/subscriber/search/${data.id}`, HttpMethod.Patch, data, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};

export const deleteOneSearch = (id: number): Promise<any> => {
  return httpClient(`/subscriber/search/${id}`, HttpMethod.Delete, null, CONSTANTS.SERVICE_TYPES.SUBSCRIBERS);
};
