import React, {useEffect, useState} from 'react';
import {UserDetailsProps} from '.';
import {CallerDetails} from '../../components/organisms/CallerDetails';
import {CallerOverView} from '../../components/organisms/CallerOverView';
import {MedicalInformation} from '../../components/organisms/MedicalInformation';
import {GeneralNotes} from '../../components/organisms/GeneralNotes';
import {MapControl, MapType} from '../../components/layouts/MapControl';
import {CallerNavigations} from '../../components/molecules/CallerNavigations';
import {Image} from '../../components/atoms/Image';
import {Images} from '../../configs/images';
import './Caller.styles.css';
import {formatDate, getMinutesStartToNow} from '../../utils/dateTimeHelper';
import {SearchLocation} from '../../components/molecules/SearchLocationInput';
import {MapLocation} from '../../components/atoms/Map';
import {CONSTANTS} from '../../constants/constants';
import {getPositionList} from '../../utils/commonFunction';

const callerNavigations = [
  {
    id: 'overview',
    label: 'Overview',
  },
  {
    id: 'contact-details',
    label: 'Contact details',
  },
  {
    id: 'medical-info',
    label: 'Medical info',
  },
  {
    id: 'general-notes',
    label: 'General notes',
  },
];

export const Details: React.FC<UserDetailsProps> = ({
  userDetails,
  medicalInformation,
  mapFullScreenState,
  setMapFullScreen,
  userInfo,
  userLocation,
  subscriberId,
  timeOfInteraction,
}) => {
  const [navName, setNavigationName] = useState<string>(
    callerNavigations[0].label
  );
  const [coordinate, setCoordinate] =
    useState<MapLocation | undefined>(undefined);
  const [locationsDetail, setLocationsDetail] = useState<MapLocation[]>([]);

  useEffect(() => {
    const locations =
      userLocation && userLocation.pathData && userLocation.pathData.length > 0
        ? userLocation.pathData.map((x) => ({
          lng: x?.longitude,
          lat: x?.latitude,
        }))
        : [];
    setLocationsDetail(locations);
  }, [userLocation]);

  const onChangeLocation = (data: SearchLocation) => {
    const position = {
      lat: data.lat,
      lng: data.lng,
    };
    setCoordinate(position);
  };

  const scrollAnimation = (idSelector: string) => {
    const querySelectorId = document.getElementById(idSelector);
    if (querySelectorId)
      querySelectorId.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  const handleScroll = (event: any) => {
    if (event.target.scrollTop < 100) {
      setNavigationName(callerNavigations[0].label);
    } else if (event.target.scrollTop < 300) {
      setNavigationName(callerNavigations[1].label);
    } else if (event.target.scrollTop < 600) {
      setNavigationName(callerNavigations[2].label);
    } else {
      setNavigationName(callerNavigations[3].label);
    }
  };

  const positionList = getPositionList(userLocation);
  const zoom =
    positionList.length > 0
      ? CONSTANTS.MAP_ZOOMS.Locate
      : CONSTANTS.MAP_ZOOMS.Default;
  const lastLocationInfo = positionList.length > 0 && {
    imageUrl: userInfo.profileImageUrl,
    fullName: userInfo.name,
    date: formatDate(
      positionList[positionList.length - 1].timestamp,
      'DD/MM/YYYY'
    ),
    time: formatDate(positionList[positionList.length - 1].timestamp, 'HH:mm'),
    timeCounter: getMinutesStartToNow(
      positionList[positionList.length - 1].createdDate
    ),
  };

  const originLocationInfo = positionList.length > 0 && {
    imageUrl: userInfo.profileImageUrl,
    fullName: userInfo.name,
    date: userInfo.dateInfoOnMap || '',
    time: userInfo.timeInfoOnMap || '',
    timeCounter: getMinutesStartToNow(positionList[0].createdDate),
  };

  return (
    <div
      className={`info-detail-container ${
        mapFullScreenState ? 'set-map-fullscreen' : 'set-map-minimize'
      }`}
    >
      <div
        className="info-detail scroll-custom"
        onScroll={(event) => handleScroll(event)}
      >
        <div className="caller-details-left">
          <div id={callerNavigations[0].id}>
            <CallerOverView callerOverViewData={userDetails} />
          </div>
          <div id={callerNavigations[1].id}>
            <CallerDetails callerDetails={userDetails} />
          </div>
          <div id={callerNavigations[2].id}>
            <MedicalInformation emergencyMedicalInfo={medicalInformation} />
          </div>
          <div id={callerNavigations[3].id}>
            <GeneralNotes notes="" />
          </div>
        </div>
        <div className="caller-navigation-right">
          <CallerNavigations
            navName={navName}
            navigations={callerNavigations}
            onNavigations={(id) => scrollAnimation(id)}
          />
        </div>
      </div>
      <div className="map-detail">
        <div
          className="map-fullscreen"
          onClick={() => setMapFullScreen(!mapFullScreenState)}
        >
          <Image
            width="16px"
            height="16px"
            url={
              mapFullScreenState ? Images.MiniGoogleMap : Images.MapFullScreen
            }
          />
        </div>
        <div className="map-container">
          <MapControl
            timeOfInteraction={timeOfInteraction}
            originLocationInfo={originLocationInfo || undefined}
            lastLocationInfo={lastLocationInfo || undefined}
            subscriberId={subscriberId}
            type={MapType.Large}
            coordinate={coordinate}
            zoomLevel={zoom}
            onChangeLocation={onChangeLocation}
            noOfCctvItems={16}
            noOfChatItems={14}
            noOfVideoItems={24}
            heightMap="100%"
            fullscreenControl={false}
            isFullScreenControl={true}
            tabName="details"
            path={locationsDetail}
          />
        </div>
      </div>
    </div>
  );
};
