import React, {useEffect} from 'react';
import {CallerProps} from '.';
import './Caller.styles.css';
import {CallerVideoControl, CallerVideoVariant} from '../../containers/CallerVideoControl';
import {UserBasicdata, UserContactdata, UserInfoPanel} from '../../components/organisms/UserInfoPanel';
import {UserDetails} from '../../components/organisms/CallsTable/CallsTable.props';
import {Tabs} from '../../components/molecules/Tabs';
import {Button, ButtonVariant} from '../../components/atoms/Button';
import {Image} from '../../components/atoms/Image';
import {CONSTANTS} from '../../constants/constants';
import {Images} from '../../configs/images';
import {Colors} from '../../configs/colors';
import {useState} from 'react';
import {Details} from './Details';
import {HistoryAndNotes} from './HistoryAndNotes';
import {Contact} from './Contact';
import {DataContacts, IncidentInteractionHistory, InteractionHistory, TimeOfIncident} from './Caller.props';
import {ContactInformationPanel} from '../../components/organisms/ContactInformationPanel';
import {WatchMeTimerSession} from '../../components/organisms/WatchMeTimerSession';
import {NoteItem, NotesPanel} from '../../components/layouts/NotesPanel';
import {ChatConversation} from '../../containers/ChatConversation';
import {DataMedicalInformation} from '../../components/organisms/MedicalInformation';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {setAgentStatus, setOutboundTaskIsConnected, setUserTaskClear, setUserTaskEnd, userTaskSelector} from '../../redux/userTaskSlice';
import {
  subscriberDetailsById,
  setSubscriberDetailsClear,
  subscriberDetailsSelector
} from '../../redux/subscriberDetailsSlice';
import {
  interactionHistoryBySubscriberId,
  interactionHistorySelector,
  setInteractionHistoryClear,
  setInteractionUpdateStatus
} from '../../redux/interactionHistorySlice';
import {
  interactionDetailsByIdForSubscriber,
  interactionDetailsSelector,
  setInteractionDetailsClear
} from '../../redux/interactionDetailsSlice';
import {setSubscriberSearchClear, subscriberSearchSelector} from '../../redux/subscriberSearchSlice';
import {addInteractionNotes} from '../../redux/interactionNotesSlice';
import {interactionStatusUpdate} from '../../redux/interactionStatusUpdateSlice';
import {interactionResolutionUpdate} from '../../redux/interactionResolutionUpdateSlice';
import {
  MedicalCondition,
  medicalConditionsReferenceData,
  setMedicalConditionsReferenceDataClear
} from '../../redux/referenceDataMedicalConditionsSlice';
import {parseHomeAddressAttribute, parseNameAttribute} from '../../utils/parsers';
import {CallerLocationItem} from '../../components/molecules/CallerLocation';
import {setUserLocationDataClear, userLocationHostoryFetch, userLocationSelector, UserLocationState} from '../../redux/userLocationSlice';
import {VideoPlayer} from '../../components/molecules/VideoPlayer';
import {getTimeStamp} from '../../utils/dateTimeHelper';
import {MobileCallInfoView} from '../../containers/MobileCallInfoView';
import {interactionVideoCallOut, setVideoCallEnd} from '../../redux/videoCallSlice';
import {interactionChatCallOut} from '../../redux/interactionChatCallOutSlice';
import {interactionPstnCallOut} from '../../redux/interactionPstnCallOutSlice';
import {notificationuserStatusBySubscriberId} from '../../redux/notificationUserStatusSlice';
import {AudioControl} from '../../components/molecules/AudioControl';
import {AcwPopUp} from '../../containers/AcwPopUp';
import {setLastKnownLocationDataClear} from '../../redux/userLastKnowLocationSlice';

const userBasicdata = {
  name: '',
  age: '',
  address: '',
  date: '',
  time: '',
  profileImageUrl: ''
};

const userContactdata = {
  mobile: '',
  home: '',
  work: '',
  video: '',
  chat: ''
};

const initialSubscriberData = {
  address: '',
  ethnicity: '',
  fullName: '',
  hairColour: '',
  height: '',
  home: '',
  livingStatus: '',
  mobile: '',
  received: '',
  time: '',
  weight: '',
  work: '',
  latitude: '',
  longitude: '',
  sex: '',
  email: ''
};

const tabs = [
  {
    label: CONSTANTS.CALLERS.DETAILS
  },
  {
    label: CONSTANTS.CALLERS.HISTORY_NOTE
  },
  {
    label: CONSTANTS.CALLERS.CONTACTS
  }
];
const contacts: Array<DataContacts> = [];

const noReponseProcedures = [
  {index: 1, labelText: 'Repeat the Missed check-in procedure in ', callType: '5 mins'},
  {index: 2, labelText: 'Repeat again in further', callType: '10 mins'},
  {index: 3, labelText: 'Leave voice mail on each of Original Caller, EC1 and EC2 with reason for call and Tunstall desk mobile number 0434 318 158', callType: ''},
  {index: 4, labelText: 'If no contact, add a Note of the actions you have taken. No further action is required beyond this.', callType: ''},
];

const initialMedicalConditions = {
  doctor: '',
  doctorMobile: '',
  bloodType: '',
  allergies: '',
  criticalMedications: '',
  otherInformation: '',
  conditions: []
};

interface NextCallDetails {
  IsPSTN: boolean;
  Number?: string;
}

export const Caller: React.FC<CallerProps> = () => {
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const subscriberDetailsSelect = useAppSelector(subscriberDetailsSelector);
  const interactionsHistorySelect = useAppSelector(interactionHistorySelector);
  const interactionsDetailsSelect = useAppSelector(interactionDetailsSelector);
  const subscriberSearchSelect = useAppSelector(subscriberSearchSelector);
  const [tabName, setTabName] = useState<string>(tabs[0].label);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [showChatSection, setShowChatSection] = useState<boolean>(true);
  const [conversationId, setConversationId] = useState<string>();
  const [subscriberId, setSubscriberId] = useState<string>('');
  const [interactionId, setInteractionId] = useState<string>('');
  const [callerType, setCallerType] = useState<string>(CONSTANTS.CALL_TYPES.NO_VIDEO);
  const [userInfoPanelBasicData, setUserInfoPanelBasicData] = useState<UserBasicdata>({...userBasicdata});
  const [userInfoPanelContactData, setUserInfoPanelContactData] = useState<UserContactdata>({...userContactdata});
  const [subscriberDetails, setSubscriberDetails] = useState<UserDetails>({...initialSubscriberData});
  const [medicalInformation, setMedicalInfomation] = useState<DataMedicalInformation>({...initialMedicalConditions});
  const [emergencyContacts , setEmergencyContacts] = useState<DataContacts[]>(contacts);
  const [interactionHistoryList , setInteractionHistoryList] = useState<IncidentInteractionHistory[]>([]);
  const [isMapFullScreen, setMapFullScreen] = useState<boolean>(true);
  const [interactionNotes, setInteractionNotes] = useState<NoteItem[]>([]);
  const [defaultInteractionNotes, setDefaultInteractionNotes] = useState<NoteItem[]>([]);
  const [defaultInteractionStatus, setDefaultInteractionStatus] = useState<string>('');
  const [defaultInteractionResolution, setDefaultInteractionResolution] = useState<string>('');
  const [selectedHistoryInteractionId, setSelectedHistoryInteractionId] = useState<string>('');
  const [noteValue, setNoteValue] = useState<string>('');
  const [isAcwPopUpOpen, setAcwPopUpOpenStatus] = useState<boolean>(false);
  const [mainStatus, setMainStatus] = useState<string>('');
  const [subStatus, setSubStatus] = useState<string>('');
  const [incident, setIncident] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [voiceCallPhoneNumber, setVoiceCallPhoneNumber] = useState<string>('');
  const userLocation = useAppSelector(userLocationSelector);
  const [callerLocation, setCallerLocation] = useState<CallerLocationItem>({
    fullName: '',
    location: '',
    time: '',
  });
  const [recordedSourceUrl, setRecordedSourceUrl] = useState<string>('');
  const [recordedChatStatus, setrecordedChatStatus] = useState<boolean>(false);
  const [selectedInteractionStatus, setSelectedInteractionStatus] = useState<string>('');
  const [selectedInteractionResolution, setSelectedInteractionResolution] = useState<string>('');
  const [incidentId, setIncidentId] = useState<string>('');
  const [subscriberActiveStatus, setSubscriberActiveStatus] = useState<boolean>(true);
  const [isInteractionInProgress, setInteractionInProgressStatus] = useState<boolean>(false);
  const [nextCallDetails, setNextCallDetails] = useState<NextCallDetails>();
  const [isSubscriberDeleted, setSubscriberDeletedStatus] = useState<boolean>(false);
  const [mapLocation, setMapLocation] = useState<UserLocationState>();
  const [timeOfInteraction, setTimeOfInteraction] = useState<TimeOfIncident>();
  const [fetchAddressInterval, setFetchAddressInterval] = useState<NodeJS.Timer>();

  useEffect(() => {
    if (!userTask.isIncomming && (userLocation?.data || userLocation?.pathData.length > 0)) {
      setMapLocation(userLocation);
    }
  }, [userLocation]);

  const SetMedicalData = (data:DataMedicalInformation) => {
    dispatch(medicalConditionsReferenceData()).then((medicalData) => {
      const medicalConditions = medicalData.payload?.map((condition:MedicalCondition) => ({
        checked: data.markedMedicalConditions?.find((i:string) => i == condition.id) ? true: false,
        name: condition.name,
        id: condition.id
      }));

      setMedicalInfomation({...medicalInformation,
        allergies: data.allergies,
        bloodType: data.bloodType,
        criticalMedications: data.criticalMedications,
        doctor: data.doctor,
        doctorMobile: data.doctorMobile,
        otherInformation: data.otherInformation,
        conditions: medicalConditions??[]
      });
    });
  };

  const setSelectedInteractionType = (selectedInteraction: InteractionHistory) => {

    dispatch(setUserLocationDataClear());

    let startTime = selectedInteraction.interactionStartDateTime;
    let endTime = selectedInteraction.interactionEndDateTime;

    if (selectedInteraction.type === CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN) {
      const watchmeRecord = interactionsHistorySelect?.data?.find(x => x.interactions.findIndex(y => y.interactionId == selectedInteraction.interactionId) > 0)?.interactions.find(y => y.type == CONSTANTS.CALL_TYPES.WATCH_ME);
      if (watchmeRecord && watchmeRecord.interactionStartDateTime && watchmeRecord.interactionEndDateTime) {
        startTime = watchmeRecord.interactionStartDateTime;
        endTime = watchmeRecord.interactionEndDateTime;
      }
    }

    // For ongoing watchme sessions we show till the current time
    if (selectedInteraction.type === CONSTANTS.CALL_TYPES.WATCH_ME && selectedInteraction.subStatus == CONSTANTS.INTERACTION_SUBSTATUS.InProgress.toLowerCase()) {
      endTime = new Date();
    }

    setTimeOfInteraction({startTime, endTime});

    if (endTime) {
      dispatch(userLocationHostoryFetch({
        subscriberId: subscriberId,
        startTime: getTimeStamp(startTime),
        endTime: getTimeStamp(endTime)
      }));
    }

    setIncident('');
    switch (selectedInteraction.type) {
    case CONSTANTS.CALL_TYPES.VIDEO_CALL:
    case CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut:
    case CONSTANTS.CALL_TYPES.CCTV_CALL:{
      if(selectedInteraction.subStatus === CONSTANTS.INTERACTION_SUBSTATUS.Missed.toLowerCase()){
        setIncidentId(selectedInteraction.incidentId);
        setCallerType(CONSTANTS.CALL_TYPES.MISSED_CALL);
      }else{
        setRecordedSourceUrl(selectedInteraction.externalSessionData??'');
        setCallerType(CONSTANTS.CALL_TYPES.RECORDED_VIDEO_CALL);
      }
      break;
    }
    case CONSTANTS.CALL_TYPES.MESSAGE:
    case CONSTANTS.CALL_OUT_CHANNELS.ChatOut:
      setrecordedChatStatus(true);
      setConversationId(selectedInteraction.externalSessionData??'');
      setCallerType(CONSTANTS.CALL_TYPES.MESSAGE);
      break;
    case  CONSTANTS.CALL_TYPES.WATCH_ME:
      setCallerType(CONSTANTS.CALL_TYPES.WATCH_ME);
      break;
    case  CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
      setCallerType(CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN);
      setIncidentId(selectedInteraction.incidentId);
      break;
    case CONSTANTS.CALL_TYPES.PSTN_CALL:
    case CONSTANTS.CALL_OUT_CHANNELS.PSTNOut:
      {if(selectedInteraction.subStatus === CONSTANTS.INTERACTION_SUBSTATUS.Missed.toLowerCase()){
        setIncidentId(selectedInteraction.incidentId);
        setCallerType(CONSTANTS.CALL_TYPES.MISSED_CALL);
      }else{
        setRecordedSourceUrl(selectedInteraction.externalSessionData??'');
        setCallerType(CONSTANTS.CALL_TYPES.RECORDED_PSTN_CALL);
      }}
      break;
    default:
      setRecordedSourceUrl(selectedInteraction.externalSessionData??'');
      setCallerType(CONSTANTS.CALL_TYPES.RECORDED_VIDEO_CALL);
      break;
    }
  };

  const resetCallerData = () => {
    setInteractionId('');
    setSubscriberId('');
    setCallerType(CONSTANTS.CALL_TYPES.NO_VIDEO);
    setConversationId('');
    setRecordedSourceUrl('');
    setIncident('');
    setMainStatus('');
    setSubStatus('');
    setIncidentId('');
    setAcwPopUpOpenStatus(false);
    setUserInfoPanelBasicData(userBasicdata);
    setUserInfoPanelContactData(userContactdata);
    setSubscriberDetails(initialSubscriberData);
    setMedicalInfomation(initialMedicalConditions);
    setEmergencyContacts([]);
    setInteractionHistoryList([]);
    dispatch(setMedicalConditionsReferenceDataClear());
    dispatch(setSubscriberDetailsClear());
    dispatch(setInteractionHistoryClear());
    dispatch(setInteractionDetailsClear());
    dispatch(setUserLocationDataClear());
    dispatch(setLastKnownLocationDataClear());
  };

  useEffect(() => {
    if ((userTask.isConnected || userTask.isMissed) && userTask.attributes) {
      console.log('userTask: ',userTask);
      dispatch(setSubscriberSearchClear());
      dispatch(setInteractionHistoryClear());
      setInteractionInProgressStatus(userTask.isConnected);
      if(userTask.attributes.incidentId){
        setIncidentId(userTask.attributes.incidentId.value);
      }
      setInteractionId(userTask.attributes.interactionId.value);
      setSelectedHistoryInteractionId(userTask.attributes.interactionId.value);
      setSubscriberId(userTask.attributes.subscriberId.value);
      setCallerLocation({
        fullName: parseNameAttribute(userTask.attributes),
        location: parseHomeAddressAttribute(userTask.attributes),
        time: '',
      });
      const interactionType = userTask.attributes.InteractionType.value;
      if(userTask.isMissed){
        setSubStatus(CONSTANTS.INTERACTION_SUBSTATUS.Missed);
        updateStatusAndEndMissedCall(true, userTask.attributes.interactionId.value, userTask.attributes.subscriberId.value);
        setCallerType(CONSTANTS.CALL_TYPES.MISSED_CALL);
      }else{
        setSubStatus(CONSTANTS.INTERACTION_SUBSTATUS.Ended);
        switch(interactionType) {
        case CONSTANTS.CALL_TYPES.VIDEO_CALL: {
          setCallerType(CONSTANTS.CALL_TYPES.VIDEO_CALL);
          break;
        }
        case CONSTANTS.CALL_TYPES.MESSAGE: {
          setrecordedChatStatus(false);
          setConversationId(userTask.attributes.conversationId.value);
          setCallerType(CONSTANTS.CALL_TYPES.MESSAGE);
          break;
        }
        case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN: {
          setCallerType(CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN);
          break;
        }
        case CONSTANTS.CALL_TYPES.PSTN_CALL: {
          setVoiceCallPhoneNumber(userTask.attributes.mobileNumber?.value || '');
          setCallerType(CONSTANTS.CALL_TYPES.PSTN_CALL);
          break;
        }
        }
      }
    } else {
      setInteractionInProgressStatus(false);
      setCallerType(CONSTANTS.CALL_TYPES.NO_VIDEO);
      if(!userTask.initializeCallOnEnd){
        if(userTask.isEnded){
          if(interactionsHistorySelect.data){
            const selectedInteraction = interactionsHistorySelect.data[0].interactions[0];
            setSelectedInteractionType(selectedInteraction);
          }
          if (fetchAddressInterval) {
            clearInterval(fetchAddressInterval);
            setFetchAddressInterval(undefined);
          }
          setAcwPopUpOpenStatus(true);
          dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.ACW));
        }else{
          resetCallerData();
        }
      }
    }
  }, [userTask.isConnected, userTask.isMissed]);

  useEffect(() => {
    const subId = localStorage.getItem('subscriberId');
    const interId = localStorage.getItem('interactionId');
    subId && setSubscriberId(subId);
    interId && setInteractionId(interId);
  }, []);

  useEffect(() => {
    if(subscriberId && interactionId){
      dispatch(interactionDetailsByIdForSubscriber({SubscriberId: subscriberId, InteractionId: interactionId}));
    }
    if (subscriberId) {
      dispatch(notificationuserStatusBySubscriberId(subscriberId)).then((statusData:any) => {
        setSubscriberActiveStatus(statusData.payload);
      });
      dispatch(interactionHistoryBySubscriberId(subscriberId));
      dispatch(subscriberDetailsById(subscriberId));
    }
  }, [subscriberId, interactionId]);

  useEffect(() => {
    if(subscriberId && interactionsHistorySelect.isInteractionUpdateAvailable){
      dispatch(interactionHistoryBySubscriberId(subscriberId));
    }else{
      dispatch(setInteractionUpdateStatus(false));
    }
  }, [subscriberId, interactionsHistorySelect.isInteractionUpdateAvailable]);

  useEffect(() => {
    if(interactionsDetailsSelect.data){
      const interactionId = interactionsDetailsSelect.data.interactionId;
      if ( interactionsHistorySelect.data &&  interactionsHistorySelect.data.length > 0) {
        const interactionsList = interactionsHistorySelect.data.map((i:IncidentInteractionHistory) => i.interactions).reduce(function(arr, e) {
          return arr.concat(e);
        });
        const selectedItem = interactionsList.find((i:InteractionHistory) => i.interactionId == interactionId);
        if(!userTask.isConnected && !userTask.isMissed && selectedItem){
          setSelectedInteractionType(selectedItem);
        }
      } else {
        setSelectedHistoryInteractionId(interactionId);
      }

      if(interactionsDetailsSelect.data.isSubscriberSnapshotAvailable){
        setSubscriberDetails(interactionsDetailsSelect.data.subscriberSnapshotData);
        SetMedicalData(interactionsDetailsSelect.data.medicalData);
        setUserInfoPanelBasicData(interactionsDetailsSelect.data.subscriberBasicInfo);
      }
      else{
        if(subscriberDetailsSelect.data){
          setSubscriberDetails(subscriberDetailsSelect.data.subscriberData);
          SetMedicalData(subscriberDetailsSelect.data.medicalData);
          const basicInfo = {...interactionsDetailsSelect.data.subscriberBasicInfo};
          basicInfo.profileImageUrl = subscriberDetailsSelect.data.subscriberData.profileImage || Images.NoProfilePictureIcon;
          basicInfo.name = subscriberDetailsSelect.data.subscriberData.fullName || 'N/A';
          basicInfo.age = subscriberDetailsSelect.data.subscriberData.age || 'N/A';
          setUserInfoPanelBasicData(basicInfo);
        }
      }
      if ((interactionsDetailsSelect.data.channel === CONSTANTS.CALL_OUT_CHANNELS.ChatOut
        || interactionsDetailsSelect.data.channel === CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut) && userTask.isConnected) {
        if (interactionsDetailsSelect.data.location?.address) {
          if (fetchAddressInterval) {
            clearInterval(fetchAddressInterval);
            setFetchAddressInterval(undefined);
          }
        }else{
          if(!fetchAddressInterval){
            const interval = setInterval(() => {
              dispatch(interactionDetailsByIdForSubscriber({SubscriberId: subscriberId, InteractionId: interactionId}));
            }, 10000);
            setFetchAddressInterval(interval);
          }
        }
      }

      if(interactionsDetailsSelect.data.status){
        setDefaultInteractionNotes(interactionsDetailsSelect.data.notes);
        setInteractionNotes(interactionsDetailsSelect.data.notes);
        setDefaultInteractionStatus(interactionsDetailsSelect.data.status);
        setDefaultInteractionResolution(interactionsDetailsSelect.data.resolution);
        setSelectedInteractionStatus(interactionsDetailsSelect.data.status);
        setSelectedInteractionResolution(interactionsDetailsSelect.data.resolution);
      }
    }else{
      setUserInfoPanelBasicData(userBasicdata);
      setSubscriberDetails(initialSubscriberData);
      setDefaultInteractionNotes([]);
      setDefaultInteractionStatus('');
      setDefaultInteractionResolution('');
      setMedicalInfomation(initialMedicalConditions);
      setInteractionHistoryList([]);
      setInteractionNotes([]);
      setSelectedInteractionStatus('');
      setSelectedInteractionResolution('');
    }
  }, [interactionsDetailsSelect.data]);

  useEffect(() => {
    if(subscriberDetailsSelect.data){      
      if(subscriberDetailsSelect.data.subscriberContactInfo){
        setUserInfoPanelContactData(subscriberDetailsSelect.data?.subscriberContactInfo);
      }

      if(subscriberDetailsSelect.data.emergencyContactList){
        setEmergencyContacts(subscriberDetailsSelect.data?.emergencyContactList);
      }
      setSubscriberDeletedStatus(subscriberDetailsSelect.data.isDeleted ?? false);

      if(!interactionId && subscriberId){
        if(subscriberDetailsSelect.data.subscriberData){
          setSubscriberDetails(subscriberDetailsSelect.data.subscriberData);
        }

        if(subscriberDetailsSelect.data.medicalData){
          SetMedicalData(subscriberDetailsSelect.data.medicalData);
        }
      }
    }else{
      setUserInfoPanelContactData(userContactdata);
      setEmergencyContacts([]);
    }
  }, [subscriberDetailsSelect.data]);

  const initiateOutboundVoiceCall = (mobileNumber:string, isWatchme?:boolean, isNewIncident?:boolean, calleeSubscriberId?: string) => {
    console.log('calling....',mobileNumber);
    setInteractionInProgressStatus(true);
    updateStatusAndEndMissedCall(true);
    // if(!userTask.isConnected){
    if(isWatchme && interactionsDetailsSelect.data && interactionsDetailsSelect.data.incidentId)
    {
      // Here we save the incident id before it gets reset
      setIncidentId(interactionsDetailsSelect.data?.incidentId);
    }
    setCallerType(CONSTANTS.CALL_TYPES.PSTN_CALL);
    dispatch(interactionPstnCallOut({subscriberId:calleeSubscriberId? calleeSubscriberId: subscriberId, incidentId: isNewIncident? '': incidentId})).then((callOutData:any) =>{
      dispatch(setUserLocationDataClear());
      dispatch(setOutboundTaskIsConnected({
        subscriberId: calleeSubscriberId? calleeSubscriberId: subscriberId,
        interactionId: callOutData.payload.interactionId,
        channel: CONSTANTS.CALL_OUT_CHANNELS.PSTNOut,
        incidentId: callOutData.payload.incidentId,
        firstName: userInfoPanelBasicData.name,
        lastName: '',
        homeAddress:  '',
        mobileNumber: mobileNumber,
        nexmoToken: callOutData.payload.jwt,
        interactionType: CONSTANTS.CALL_TYPES.PSTN_CALL
      }));
      dispatch(interactionHistoryBySubscriberId(subscriberId));
      dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.OnInteraction));
    }).catch(error =>{
      setInteractionInProgressStatus(false);
    });
    //}
  };

  const handelAddNoteClick = (note:string) => {
    setNoteValue(note);
  };

  const initiateOutboundChat = (calleeSubscriberId?: string) => {
    updateStatusAndEndMissedCall(true);
    if(!userTask.isConnected){
      setInteractionInProgressStatus(true);
      setCallerType(CONSTANTS.CALL_TYPES.MESSAGE);
      dispatch(interactionChatCallOut({subscriberId: calleeSubscriberId? calleeSubscriberId: subscriberId, incidentId:''})).then((callOutData:any) =>{
        dispatch(setUserLocationDataClear());
        dispatch(setOutboundTaskIsConnected({
          subscriberId: calleeSubscriberId? calleeSubscriberId: subscriberId,
          interactionId: callOutData.payload.interactionId,
          channel: CONSTANTS.CALL_OUT_CHANNELS.ChatOut,
          incidentId: callOutData.payload.incidentId,
          firstName: userInfoPanelBasicData.name,
          lastName: '',
          homeAddress:  '',
          conversationId: callOutData.payload.conversationId,
          nexmoToken: callOutData.payload.jwt,
          interactionType: CONSTANTS.CALL_TYPES.MESSAGE
        }));
        dispatch(interactionHistoryBySubscriberId(subscriberId));
        dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.OnInteraction));
      }).catch(error =>{
        setInteractionInProgressStatus(false);
      });
    }
  };

  useEffect(() => {
    if (interactionsHistorySelect.data && interactionsHistorySelect.data.length > 0) {
      if (!userTask.isConnected && !userTask.isMissed && subscriberId) {
        if(!interactionId ){
          const selectedInteraction = interactionsHistorySelect.data[0].interactions[0];
          setInteractionId(selectedInteraction.interactionId);
          setSelectedHistoryInteractionId(selectedInteraction.interactionId);
          setSelectedInteractionType(selectedInteraction);
        }
      }

      const incident = interactionsHistorySelect.data.find(x => x.incidentId == incidentId);
      const selectedInteraction = incident?.interactions.find(x => x.interactionId == interactionId);
      if(selectedInteraction && selectedInteraction.type == CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN)
      {
        setSelectedInteractionType(selectedInteraction);
      }

      if(selectedHistoryInteractionId){
        const interactionsList = interactionsHistorySelect.data.map((i:IncidentInteractionHistory) => i.interactions).reduce(function(arr, e) {
          return arr.concat(e);
        });
        const selectedItem = interactionsList.find((i:InteractionHistory) => i.interactionId == selectedHistoryInteractionId);
        if(selectedItem){
          setInteractionNotes(selectedItem.notes);
          setSelectedInteractionStatus(selectedItem.status);
          setSelectedInteractionResolution(selectedItem.resolution??'');
          if(!userTask.isConnected && !userTask.isMissed){
            setSelectedInteractionType(selectedItem);
          }
        }
      }

      setInteractionHistoryList(interactionsHistorySelect.data);
    } else {
      if (!userTask.isConnected && !userTask.isMissed) {
        interactionsHistorySelect.data?.length == 0 && handleChangeTab(CONSTANTS.CALLERS.DETAILS);
        setCallerType(CONSTANTS.CALL_TYPES.NO_VIDEO);
      }
      setInteractionHistoryList([]);
    }
  }, [interactionsHistorySelect.data]);

  useEffect(() => {
    if(noteValue){
      dispatch(addInteractionNotes({InteractionId: selectedHistoryInteractionId, Note: noteValue})).then((noteData:any) => {
        setNoteValue('');
        if(noteData.payload.interactionId == interactionId){
          dispatch(interactionDetailsByIdForSubscriber({SubscriberId: subscriberId, InteractionId: interactionId}));
        }else{
          dispatch(interactionHistoryBySubscriberId(subscriberId));
        }
      });
    }
  }, [noteValue]);

  useEffect(() => {
    if(userTask.initializeCallOnEnd && subscriberDetails.mobile)
    {
      if(nextCallDetails && nextCallDetails.IsPSTN && nextCallDetails.Number)
      {
        initiateOutboundVoiceCall(nextCallDetails.Number, true);
      }
      if(nextCallDetails && !nextCallDetails.IsPSTN)
      {
        initiateOutboundVideoCall();
      }
      setNextCallDetails(undefined);
    }
  }, [userTask.initializeCallOnEnd]);

  useEffect(() => {
    if(!userTask.isConnected && !userTask.initializeCallOnEnd){
      if(subscriberSearchSelect.selectedObjectId){
        subscriberId !== subscriberSearchSelect.selectedObjectId && resetCallerData();
        if(interactionsDetailsSelect.selectedObjectId){
          setInteractionId(interactionsDetailsSelect.selectedObjectId);
          setSelectedHistoryInteractionId(interactionsDetailsSelect.selectedObjectId);
          handleChangeTab(CONSTANTS.CALLERS.DETAILS);
        }else{
          handleChangeTab(CONSTANTS.CALLERS.HISTORY_NOTE);
        }
        setSubscriberId(subscriberSearchSelect.selectedObjectId);        
      }
    }
  }, [subscriberSearchSelect.selectedObjectId, interactionsDetailsSelect.selectedObjectId]);

  const updateStatusAndEndMissedCall = (isEndCall: boolean, interactionId?: string, selectedSubscriberId?: string) => {
    if (userTask.isMissed) {
      isEndCall && dispatch(setVideoCallEnd());
      dispatch(interactionStatusUpdate({
        InteractionId: interactionId || selectedHistoryInteractionId,
        MainStatus: CONSTANTS.INTERACTION_STATUS.Closed,
        SubStatus: CONSTANTS.INTERACTION_SUBSTATUS.Missed})).then(() => {
        dispatch(interactionHistoryBySubscriberId(selectedSubscriberId || subscriberId));
      });
    }
  };

  useEffect(() => {
    if(mainStatus && subStatus && incident && isAcwPopUpOpen){
      dispatch(setUserTaskClear());
      dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.Available));

      dispatch(interactionStatusUpdate({InteractionId: interactionId, MainStatus: mainStatus, SubStatus: subStatus})).then(statusData =>{
        setMainStatus('');
        setSubStatus('');
      });

      dispatch(interactionResolutionUpdate({InteractionId: interactionId, Incident:incident})).then(() =>{
        setIncident('');
        setIncidentId('');
        dispatch(interactionHistoryBySubscriberId(subscriberId));
        handleChangeTab(CONSTANTS.CALLERS.HISTORY_NOTE);
        setAcwPopUpOpenStatus(false);
      });
    }
    else if(!isAcwPopUpOpen){
      if(mainStatus && subStatus){
        dispatch(interactionStatusUpdate({InteractionId: selectedHistoryInteractionId, MainStatus: mainStatus, SubStatus: subStatus})).then(statusData =>{
          setMainStatus('');
          setSubStatus('');
          dispatch(interactionHistoryBySubscriberId(subscriberId));
        });
      }

      if(incident){
        dispatch(interactionResolutionUpdate({InteractionId: selectedHistoryInteractionId, Incident:incident})).then(() =>{
          setIncident('');
        });
      }
    }
  }, [mainStatus, subStatus, incident]);

  const initiateOutboundVideoCall = (isNewIncident?:boolean, calleeSubscriberId?: string) => {
    updateStatusAndEndMissedCall(true);
    setInteractionInProgressStatus(true);
    setCallerType(CONSTANTS.CALL_TYPES.VIDEO_CALL);
    dispatch(interactionVideoCallOut({
      incidentId: isNewIncident? '': incidentId,
      subscriberId: calleeSubscriberId? calleeSubscriberId: subscriberId
    })).then((calloutData: any) => {
      dispatch(setUserLocationDataClear());
      dispatch(setOutboundTaskIsConnected({
        subscriberId: calleeSubscriberId? calleeSubscriberId: subscriberId,
        interactionId: calloutData.payload.interactionId,
        channel: CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut,
        incidentId: incidentId,
        firstName: userInfoPanelBasicData.name,
        lastName: '',
        homeAddress:  userInfoPanelBasicData.address,
        interactionType: CONSTANTS.CALL_TYPES.VIDEO_CALL,
        age: userInfoPanelBasicData.age
      }));
      dispatch(interactionHistoryBySubscriberId(subscriberId));
      dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.OnInteraction));
    }).catch(() =>{
      setInteractionInProgressStatus(false);
    });
  };

  const onCompleteClick = () => {
    // If the user concludes without any call we disconnect task and resolve
    if (userTask.isConnected) {
      dispatch(setUserTaskEnd(false));
    } else {
      // If the user revisits task and close we update resolution
      setAcwPopUpOpenStatus(true);
    }
  };

  const handleButtonClick = (type: string) => {
    switch (type) {
    case CONSTANTS.CALL_TYPES.VOICE_CALL:
      if (subscriberDetails.mobile) {
        setNextCallDetails({IsPSTN: true, Number: subscriberDetails.mobile});
        dispatch(setUserTaskEnd(true));
      }
      break;
    case CONSTANTS.CALL_TYPES.VIDEO_CALL:
      setNextCallDetails({IsPSTN: false});
      dispatch(setUserTaskEnd(true));
      break;
    case CONSTANTS.CALL_TYPES.OOO:
      initiateOutboundVoiceCall('000', true);
      break;
    case 'EC':
      contactCall();
      break;

    default:
      break;
    }
  };

  const contactCall= () => {
    const watchMeConfig = subscriberDetailsSelect.data?.watchmeConfig;
    if (watchMeConfig) {
      emergencyContacts.find(x => {
        if (x.id == watchMeConfig.primaryContactId) {
          if (x.mobile) {
            setNextCallDetails({IsPSTN: true, Number: x.mobile.countryCode + x.mobile.number});
            dispatch(setUserTaskEnd(true));
          }
        }
        if (x.id == watchMeConfig.secondaryContactId) {
          if (x.mobile) {
            setNextCallDetails({IsPSTN: true, Number: x.mobile.countryCode + x.mobile.number});
            dispatch(setUserTaskEnd(true));
          }
        }
      });
    }
  };
  const renderCallerLeft = (type: string) => {
    switch (type) {
    case CONSTANTS.CALL_TYPES.VIDEO_CALL:
      return <CallerVideoControl
        variant={CallerVideoVariant.ActiveWith1Video}
        isDeviceControlTextVisible
        emergencyContacts={emergencyContacts}
        onOutgoingVideoCallAutomaticEnd={onOutgoingVideoCallAutomaticEnd}
        address={userInfoPanelBasicData.address}
        date={userInfoPanelBasicData.date}
        time={userInfoPanelBasicData.time}
        userLocation={mapLocation}
        profileImage={userInfoPanelBasicData.profileImageUrl}
      />;
    case CONSTANTS.CALL_TYPES.MESSAGE:
      return <div className={`${showChatSection ? 'chat-conversation-section' : 'hide-conversation'}`}>
        <ChatConversation conversationId={conversationId} recordedStatus={recordedChatStatus} subscriberId={subscriberId} subscriberImage={userInfoPanelBasicData.profileImageUrl}/>
        <div onClick={() => setShowChatSection(!showChatSection)} className="close-open-conversation">
          <Image width="12px" url={showChatSection ? Images.ImgCaretGeyLeft : Images.ImgCaretGeyRight} />
        </div>
      </div>;
    case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
    case CONSTANTS.CALL_TYPES.WATCH_ME:
      return <WatchMeTimerSession
        name={subscriberDetails.fullName || ''}
        onCompleteClick={onCompleteClick}
        noReponseProcedures={noReponseProcedures}
        subscriberActiveStatus={subscriberActiveStatus}
        interactionsDetailsSelect={interactionsDetailsSelect.data}
        subscriberDetailsSelect={subscriberDetailsSelect.data}
        callerType={callerType}
        onButtonClick={(type) => handleButtonClick(type || '')}
        emergencyContacts={emergencyContacts}
        userLocation={mapLocation}
      />;
    case CONSTANTS.CALL_TYPES.RECORDED_VIDEO_CALL:
      return <VideoPlayer
        url={recordedSourceUrl}
        width="100%"
        height="100%"
        userInfoPanel={userInfoPanelBasicData}
        userLocation={mapLocation}
      />;
    case CONSTANTS.CALL_TYPES.PSTN_CALL:
      return <MobileCallInfoView
        phoneNumber={voiceCallPhoneNumber}
        profileImage={userInfoPanelBasicData.profileImageUrl}
        address={''}
        date={''}
        subscriberId={subscriberId}
        interactionId={interactionId}
      />;
    case CONSTANTS.CALL_TYPES.MISSED_CALL:
      return <CallerVideoControl variant={CallerVideoVariant.HungUp} handlePstnCall={initiateOutboundVoiceCall} handleOutgoingCall={initiateOutboundVideoCall} mobileNumber={userInfoPanelContactData.mobile} isVideoButtonDisabled={!subscriberActiveStatus}/>;
    case CONSTANTS.CALL_TYPES.RECORDED_PSTN_CALL:
      return <AudioControl
        url={recordedSourceUrl}
        profileImage={userInfoPanelBasicData.profileImageUrl}
        width="100%"
        height="100%"
        address={userInfoPanelBasicData.address}
        date={userInfoPanelBasicData.date}
        time={userInfoPanelBasicData.time}
        duration={userInfoPanelBasicData.duration}
      />;
    default:
      return <CallerVideoControl variant={CallerVideoVariant.NoVideo} />;
    }
  };

  const onClickRowShowNotes = (data: InteractionHistory,showNotes: boolean) => {
    if (!userTask.isConnected) {
      setInteractionId(data.interactionId);
      setSelectedInteractionType(data);
    }
    setSelectedHistoryInteractionId(data.interactionId);
    setInteractionNotes(data.notes);
    setSelectedInteractionStatus(data.status);
    setSelectedInteractionResolution(data.resolution ?? '');

    if (showNotes)
      setShowNotes(true);
  };

  const handleChangeTab = (tabName: string) => {
    setTabName(tabName);
    selectedTab !== tabName && setShowNotes(false);    
    setSelectedTab(tabName);
    setSelectedHistoryInteractionId(interactionId);
    setInteractionNotes(defaultInteractionNotes);
    setSelectedInteractionStatus(defaultInteractionStatus);
    setSelectedInteractionResolution(defaultInteractionResolution);
  };

  const handleCloseNotesPanel = () => {
    setShowNotes(false);
    setSelectedHistoryInteractionId(interactionId);
    setInteractionNotes(defaultInteractionNotes);
    setSelectedInteractionStatus(defaultInteractionStatus);
    setSelectedInteractionResolution(defaultInteractionResolution);
  };

  const updateStatusAndIncident = (status: string, incident:string) => {
    if (status === CONSTANTS.INTERACTION_STATUS.Open) {
      setMainStatus(CONSTANTS.INTERACTION_STATUS.Open);
    } else {
      setMainStatus(CONSTANTS.INTERACTION_STATUS.Closed);
    }
    if(!subStatus){
      setSubStatus(CONSTANTS.INTERACTION_SUBSTATUS.Ended);
    }
    setIncident(incident);
  };

  const handleNotePanelStatusSelect = (status:string) => {
    setMainStatus(status);
    setSubStatus(CONSTANTS.INTERACTION_SUBSTATUS.Ended);
  };

  const handleNotePanelIncidentSelect = (incident:string) => {
    setIncident(incident);
  };

  const onOutgoingVideoCallAutomaticEnd = () => {
    setSubStatus(CONSTANTS.INTERACTION_SUBSTATUS.Unanswered);
  };

  useEffect(() => {
    if(subscriberId){
      switch(selectedTab) { 
      case CONSTANTS.CALLERS.HISTORY_NOTE: { 
        dispatch(interactionHistoryBySubscriberId(subscriberId));
        break; 
      } 
      case CONSTANTS.CALLERS.CONTACTS: { 
        break; 
      } 
      } 
    }
  }, [selectedTab]);

  return (
    <div className={`caller-style ${showChatSection ? '' : 'hide-caller-left'}`}>
      <div className="caller-left">
        {
          renderCallerLeft(callerType)
        }
      </div>
      <div className="caller-right">
        <div className="caller-right-info">
          <UserInfoPanel onClickMobile={initiateOutboundVoiceCall} userBasicdata={userInfoPanelBasicData} userContactdata={userInfoPanelContactData} isInteractionButtonDisabled={isInteractionInProgress || isSubscriberDeleted} onClickChat={initiateOutboundChat} onVideoClick={initiateOutboundVideoCall} isVideoCallDisabled={!subscriberActiveStatus}/>
        </div>
        <div className="caller-navigation">
          <div className="caller-navigation-header">
            <Tabs tabName={tabName} tabs={tabs} onTabs={(label) => handleChangeTab(label)} />
            {!showNotes &&
                <Button
                  variant={ButtonVariant.Round}
                  onClick={() => setShowNotes(true)}
                  text={'Caller notes'}
                  textColor={Colors.LSDarkTeal}
                  fontSize={15}
                  fontWeight={500}
                >
                  <Image width="24px" url={Images.ListDashes} />
                </Button>
            }
            {showNotes &&
                <Button
                  textColor={Colors.LSDarkTeal}
                  variant={ButtonVariant.Round}
                  text={'Close'}
                  onClick={() => handleCloseNotesPanel()}
                  fontSize={15}
                  fontWeight={500}
                >
                  <Image width="24px" url={Images.ActiveClose} />
                </Button>
            }
          </div>
          {
            (showNotes) &&
              <div className={`${tabName === CONSTANTS.CALLERS.HISTORY_NOTE ? 'caller-notes-panel-history' : 'caller-notes-panel'}`}>
                <NotesPanel data={interactionNotes} onAddNotes={handelAddNoteClick} onSelectStatus={handleNotePanelStatusSelect} onSelectIncident={handleNotePanelIncidentSelect} selectedStatusValue={selectedInteractionStatus} selectedResolutionValue={selectedInteractionResolution}/>
              </div>
          }
          <div className="caller-navigation-content">
            {
              tabName === CONSTANTS.CALLERS.DETAILS &&
              <Details
                userDetails={subscriberDetails}
                medicalInformation= {medicalInformation}
                mapFullScreenState={isMapFullScreen}
                setMapFullScreen={setMapFullScreen}
                userInfo={userInfoPanelBasicData}
                userLocation={mapLocation}
                callerLocation={callerLocation}
                isConnected={userTask.isConnected}
                subscriberId={subscriberId}
                timeOfInteraction={timeOfInteraction}
              />
            }
            {
              tabName === CONSTANTS.CALLERS.HISTORY_NOTE &&
              <HistoryAndNotes
                onClickRowShowNotes={onClickRowShowNotes}
                setTabName={(label) => setTabName(label)}
                showNotes={showNotes}
                subscriberId={subscriberId}
                interactionHistory={interactionHistoryList}
                selectedInteractionId = {interactionId}
              />
            }
            {
              tabName === CONSTANTS.CALLERS.CONTACTS &&
                <Contact
                  mapFullScreenState={isMapFullScreen}
                  setMapFullScreen={setMapFullScreen}
                  userLocation={mapLocation}
                  callerLocation={callerLocation}
                  userInfo={userInfoPanelBasicData}
                  isConnected={userTask.isConnected}
                  subscriberId={subscriberId}
                  timeOfInteraction={timeOfInteraction}
                  onClickMobile={initiateOutboundVoiceCall}
                  onClickChat={initiateOutboundChat} 
                  onVideoClick={initiateOutboundVideoCall}
                  isInteractionButtonDisabled={isInteractionInProgress || isSubscriberDeleted}
                />
            }
          </div>
        </div>
      </div>
      {isAcwPopUpOpen && (
        <AcwPopUp
          onSubmit={updateStatusAndIncident}
        ></AcwPopUp>
      )}
    </div >
  );
};
