import React, {useEffect, useState} from 'react';
import {MedicalInformationProps} from '.';
import {CheckBoxField} from '../../molecules/CheckBoxField';
import {InfoPanel} from '../../molecules/InfoPanel';
import {InputLabel} from '../../molecules/InputLabel';
import './MedicalInformation.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {Condition} from './MedicalInformation.props';

export const MedicalInformation: React.FC<MedicalInformationProps> = ({
  emergencyMedicalInfo
}) => {
  // const [isEdit, setIsEdit] = useState<boolean>(true);
  const [doctor, setDoctorName] = useState<string>(emergencyMedicalInfo.doctor || '');
  const [doctorMobile, setDoctorMobile] = useState<string>(emergencyMedicalInfo.doctorMobile);
  const [bloodType, setBloodType] = useState<string>(emergencyMedicalInfo.bloodType || '');
  const [allergies, setAllergies] = useState<string>(emergencyMedicalInfo.allergies || '');
  const [criticalMedications, setCriticalMedications] = useState<string>(emergencyMedicalInfo.criticalMedications || '');
  const [otherInformation, setOtherInformation] = useState<string>(emergencyMedicalInfo.otherInformation || '');
  const [medicalInfoConditions, setMedicalInfoConditions] = useState(emergencyMedicalInfo.conditions || []);

  const onChangeInput = (event: any, type: string) => {
    switch (type) {
    case CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.DOCTOR_NAME:
      emergencyMedicalInfo.doctor = event.target.value;
      setDoctorName(event.target.value);
      break;
    case CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.DOCTORS_NO:
      emergencyMedicalInfo.doctorMobile = event.target.value;
      setDoctorMobile(event.target.value);
      break;
    case CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.BLOOD_TYPE:
      emergencyMedicalInfo.bloodType = event.target.value;
      setBloodType(event.target.value);
      break;
    case CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.OTHER_INFORMATION:
      emergencyMedicalInfo.otherInformation = event.target.value;
      setOtherInformation(event.target.value);
      break;
    case CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.CRITICAL_MEDICATIONS:
      emergencyMedicalInfo.criticalMedications = event.target.value;
      setCriticalMedications(event.target.value);
      break;
    case CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.ALLERGIES:
      emergencyMedicalInfo.allergies = event.target.value;
      setAllergies(event.target.value);
      break;

    default:
      break;
    }
    console.log(emergencyMedicalInfo);
    // use medicalInformation to submit data
  };
  const handleCheckbox = (condition: Condition) => {
    medicalInfoConditions.map(item => {
      if (item.name === condition.name) {
        item.checked = !condition.checked;
      }
    });
    setMedicalInfoConditions([...medicalInfoConditions]);
    emergencyMedicalInfo.conditions = medicalInfoConditions;
    console.log(medicalInfoConditions);
    // use medicalInformation to submit data
  };

  useEffect(() => {
    setDoctorName(emergencyMedicalInfo.doctor);
    setDoctorMobile(emergencyMedicalInfo.doctorMobile);
    setBloodType(emergencyMedicalInfo.bloodType);
    setAllergies(emergencyMedicalInfo.allergies);
    setCriticalMedications(emergencyMedicalInfo.criticalMedications);
    setOtherInformation(emergencyMedicalInfo.otherInformation);
    setMedicalInfoConditions(emergencyMedicalInfo.conditions);
  }, [emergencyMedicalInfo]);

  return (
    <div className="medical-info-style">
      <InfoPanel width="100%" title={CONSTANTS.CALLERS.MEDICAL_INFORMATION} />
      <div className="medical-info-detail">
        <div className="medical-info">
          <InputLabel
            type="text"
            disabledInput={true}
            label={CONSTANTS.MEDICAL_INFORMATION.LABEL.DOCTOR_NAME}
            value={doctor}
            onChangeInput={(event) => onChangeInput(event, CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.DOCTOR_NAME)}
            widthInput="calc(100% - 106px)"
            widthInputLabel="90px"
          />
          <InputLabel
            type="text"
            disabledInput={true}
            label={CONSTANTS.MEDICAL_INFORMATION.LABEL.DOCTORS_NO}
            value={doctorMobile}
            onChangeInput={(event) => onChangeInput(event, CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.DOCTORS_NO)}
            textColor={true}
            widthInput="calc(100% - 116px)"
            widthInputLabel="100px"
          />
          <InputLabel
            type="text"
            disabledInput={true}
            label={CONSTANTS.MEDICAL_INFORMATION.LABEL.BLOOD_TYPE}
            value={bloodType}
            onChangeInput={(event) => onChangeInput(event, CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.BLOOD_TYPE)}
            widthInput="calc(100% - 106px)"
            widthInputLabel="90px"
          />
        </div>
        <div className="emergency-medical">
          {medicalInfoConditions &&
            medicalInfoConditions.map((item: Condition, index: number) =>
              <CheckBoxField
                key={index}
                disabled={true}
                value={item.checked}
                title={item.name}
                onChange={() => handleCheckbox(item)}
              />)
          }
        </div>
        <div className="emergency-medications">
          <InputLabel
            type="text"
            disabledInput={true}
            label={CONSTANTS.MEDICAL_INFORMATION.LABEL.ALLERGIES}
            value={allergies}
            onChangeInput={(event) => onChangeInput(event, CONSTANTS.MEDICAL_INFORMATION.LABEL.ALLERGIES)}
            widthInput="85%"
            widthInputLabel="15%"
          />
          <InputLabel
            type="text"
            disabledInput={true}
            label={CONSTANTS.MEDICAL_INFORMATION.LABEL.CRITICAL_MEDICATIONS}
            value={criticalMedications}
            onChangeInput={(event) => onChangeInput(event, CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.CRITICAL_MEDICATIONS)}
            widthInput="85%"
            widthInputLabel="15%"
          />
        </div>
        <div className="emergency-notes">
          <InputLabel
            type="text"
            disabledInput={true}
            label={CONSTANTS.MEDICAL_INFORMATION.LABEL.OTHER_INFORMATION}
            value={otherInformation}
            onChangeInput={(event) => onChangeInput(event, CONSTANTS.MEDICAL_INFORMATION.TYPE_CHANGE.OTHER_INFORMATION)}
            widthInput="85%"
            widthInputLabel="15%"
          />
        </div>
      </div>
    </div>
  );
};
