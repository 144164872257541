import {MapLocation} from './../components/atoms/Map/Map.props';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getSubscriberById} from '../api/subscribers';
import {Images} from '../configs/images';
import {LastKnowLocationData, SortColumn} from '../pages/Caller';
import {UserDetails} from '../components/organisms/CallsTable';
import {DataMedicalInformation} from '../components/organisms/MedicalInformation';
import {formatDate, getDateTimeDifference} from '../utils/dateTimeHelper';

export interface SubscriberContactInfoObject {
  mobile: string;
  home: string;
  work: string;
  video: string;
  chat: string;
}

export interface TelephoneNumber {
  number: string;
  countryCode: string;
}

export interface EmergencyContactObject {
  id: string;
  subScriberID: string;
  contactName: string;
  group: string;
  relationship: string;
  contact: string;
  currentLocation: string;
  lastFix: string;
  distance: string;
  distanceValue: number;
  url: string;
  type: string;
  home: string;
  work: string;
  mobile: TelephoneNumber;
  contactSubscriberId?:string;
  roamAIUserId?:string;
  location?: MapLocation;
  subscriberLocation?: MapLocation;
  times?: string;
  showContactMarker?: boolean;
  activity?: string;
  homeAddress?: string;
}

export interface SubscriberDetailsObject {
  subscriberContactInfo: SubscriberContactInfoObject;
  emergencyContactList: Array<EmergencyContactObject>;
  subscriberData: UserDetails;
  medicalData: DataMedicalInformation;
  watchmeConfig?: WatchMeConfig,
  isDeleted?: boolean;
}

export interface SubscriberDetailsObjectState {
  data?: SubscriberDetailsObject;
  isLoading: boolean;
  error?: string;
}

export interface WatchMeConfig {
  primaryContactId: string;
  secondaryContactId: string;
  useEmergencyService: boolean;
}

const initialState: SubscriberDetailsObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const subscriberDetailsById = createAsyncThunk('subscriberDetails/ById', async (subscriberId: string) => {
  const response = await getSubscriberById(subscriberId);
  const data = response.lifestreamResponse.data;
  return {
    subscriberContactInfo: {
      mobile: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
      home: `${data.additionalDetails?.homeNumber?.countryCode || ''}${data.additionalDetails?.homeNumber?.number || ''}`,
      work: `${data.additionalDetails?.workNumber?.countryCode || ''}${data.additionalDetails?.workNumber?.number || ''}`,
      video: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
      chat: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
    },
    emergencyContactList: data.emergencyContacts?.map((contact: any) => ({
      id: contact.id,
      subScriberID: subscriberId,
      contactName: `${contact.firstName} ${contact.lastName}`,
      group: 'EC',
      relationship: contact.relationship,
      contact: `${contact.mobileNumber.countryCode}${contact.mobileNumber.number}`,
      lastFix: '',
      distance: '',
      distanceValue: 0,
      url: contact.photo ?? Images.NoProfilePictureIcon,
      type: contact.contactType,
      home:`${contact.homeNumber?.countryCode || ''}${contact.homeNumber?.number || ''}`,
      work:`${contact.workNumber?.countryCode || ''}${contact.workNumber?.number || ''}`,
      mobile: contact.mobileNumber,
      contactSubscriberId: contact.contactSubscriberId,
      firstName: contact.firstName ?? '',
      location: undefined,
      subscriberLocation: undefined,
      showContactMarker: true,
      homeAddress: contact.homeAddress ? contact.homeAddress : 'N/A',
      currentLocation: '',
    })) || [],
    subscriberData: {
      id: data.account.subscriberId,
      address: data.account.homeAddress,
      email: data.account.emailAddress,
      ethnicity: data.additionalDetails?.ethnicity,
      fullName: `${data.account.firstName || 'N/A'} ${data.account.lastName || ''}`,
      hairColour: data.additionalDetails?.hairColor,
      height: data.additionalDetails?.heightInCm,
      weight: data.additionalDetails?.weightInKg,
      home: `${data.additionalDetails?.homeNumber?.countryCode??''}${data.additionalDetails?.homeNumber?.number??''}`,
      livingStatus: data.subscriberSnapshot?.additionalDetails?.livingStatus,
      mobile: `${data.account.mobileNumber.countryCode}${data.account.mobileNumber.number}`,
      sex: data.additionalDetails?.gender,
      work: `${data.additionalDetails?.workNumber?.countryCode??''}${data.additionalDetails?.workNumber?.number??''}`,
      profileImage: data.profilePhoto?.image?? Images.NoProfilePictureIcon,
      age: getDateTimeDifference(data.account.dateOfBirth).toString()
    },
    medicalData:{
      allergies: data.additionalDetails?.allergyDetails,
      bloodType: data.additionalDetails?.bloodType,
      criticalMedications: data.additionalDetails?.criticalMedications,
      doctor: data.additionalDetails?.doctorsName,
      doctorMobile: `${data.additionalDetails?.doctorsNumber?.countryCode??''}${data.additionalDetails?.doctorsNumber?.number??''}`,
      otherInformation: data.additionalDetails?.otherInformation,
      conditions: [],
      markedMedicalConditions: data.additionalDetails?.medicalConditions
    },
    watchmeConfig: data.watchMeConfig,
    isDeleted: data.account.isDeleted
  };
});

const subscriberDetailsSlice = createSlice({
  name: 'subscriberDetails',
  initialState,
  reducers: {
    setSubscriberDetailsData: (state, action: PayloadAction<SubscriberDetailsObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as SubscriberDetailsObject;
      state.error = undefined;
    },
    setSubscriberDetailsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },
    sortSubscriberContactData: (state, action:PayloadAction<any>) => {
      if(state.data){
        switch (action.payload.SortBy) {
        case SortColumn.ContactName:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.contactName.localeCompare(b.contactName) : b.contactName.localeCompare(a.contactName));
          break;
        
        case SortColumn.Group:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.group.localeCompare(b.group) : b.group.localeCompare(a.group));
          break;
        
        case SortColumn.Relationship:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.relationship.localeCompare(b.relationship) : b.relationship.localeCompare(a.relationship));
          break;
        
        case SortColumn.Distance:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? (a.distanceValue - b.distanceValue) : (b.distanceValue - a.distanceValue));
          break;
        
        // TODO : We'll have to change the logic based on the type we get here for dates
        case SortColumn.LastFix:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.lastFix.localeCompare(b.lastFix) : b.lastFix.localeCompare(a.lastFix));
          break;
        }
      }
    },
    updateSubscriberContactLocation: (state: SubscriberDetailsObjectState, action:PayloadAction<LastKnowLocationData[]>) => {
      if(state.data){
        action.payload.forEach(function(contactLocation){
          const index = state.data?.emergencyContactList.findIndex(x => x.contactSubscriberId === contactLocation.subscriberId);
          if(state.data && index != undefined && index > -1){
            state.data.emergencyContactList[index].roamAIUserId = contactLocation.roamAIUserId;
            state.data.emergencyContactList[index].currentLocation = contactLocation.currentLocation || 'N/A';
            state.data.emergencyContactList[index].lastFix = contactLocation.recordedAt? formatDate(contactLocation.recordedAt, 'DD/MM/yy HH:mm:ss'): 'N/A';
            state.data.emergencyContactList[index].times = contactLocation.recordedAt? formatDate(contactLocation.recordedAt, 'HH:mm'): 'N/A';
            state.data.emergencyContactList[index].location = contactLocation.latitude && contactLocation.longitude ? {lat: contactLocation.latitude, lng: contactLocation.longitude} : undefined;
            state.data.emergencyContactList[index].distance = contactLocation.distance || 'N/A';
            state.data.emergencyContactList[index].distanceValue = contactLocation.distanceValue || 0;
            state.data.emergencyContactList[index].activity = contactLocation.activity;
          }
        });

        //Sort contact data after updating locations
        let hasDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue > 0);
        hasDistanceValue = hasDistanceValue.sort((a, b) => a.distanceValue - b.distanceValue);
        let nonDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue === 0);
        nonDistanceValue = nonDistanceValue.sort((a, b) => a.contactName.localeCompare(b.contactName));
        state.data.emergencyContactList = hasDistanceValue.concat(nonDistanceValue);
      }
    },
    setEmergencyContactListDefaultSort: (state: SubscriberDetailsObjectState) => {
      if(state.data){
        let hasDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue > 0);
        hasDistanceValue = hasDistanceValue.sort((a, b) => a.distanceValue - b.distanceValue);
        let nonDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue === 0);
        nonDistanceValue = nonDistanceValue.sort((a, b) => a.contactName.localeCompare(b.contactName));
        state.data.emergencyContactList = hasDistanceValue.concat(nonDistanceValue);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriberDetailsById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(subscriberDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as SubscriberDetailsObject;
      state.error = undefined;
    });

    builder.addCase(subscriberDetailsById.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setSubscriberDetailsData,
  setSubscriberDetailsClear,
  sortSubscriberContactData,
  updateSubscriberContactLocation,
  setEmergencyContactListDefaultSort
} = subscriberDetailsSlice.actions;

export const subscriberDetailsSelector = (state: RootState) => state.subscriberDetails;

export default subscriberDetailsSlice.reducer;
