import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {Images} from '../configs/images';
import {callOutInteraction, getInteractionByIdForSubscriber, pstnCallHoldAndResume} from '../api/interactions';
import {formatDate, getDateTimeDifference} from '../utils/dateTimeHelper';
import {sortInteractionNotesData} from '../utils/dataPreparationHelper';
import {UserDetails} from '../components/organisms/CallsTable';
import {DataMedicalInformation} from '../components/organisms/MedicalInformation';
import {UserBasicdata} from '../components/organisms/UserInfoPanel';
import {NoteItem} from '../components/layouts/NotesPanel';
import {acceptInteraction} from '../api/interactions';

export interface InteractionPstnCallOutObject {
  incidentId: string;
  interactionId: string;
  jwt: string;
}

export interface InteractionPstnCallOutObjectState {
  data?: InteractionPstnCallOutObject;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionPstnCallOutObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const pstnCallHoldAndResumeByCallId = createAsyncThunk('callHoldAndResume/CallId', async (pstnCallParam: any) => {
  const response = await pstnCallHoldAndResume(pstnCallParam.callId, pstnCallParam.action);
  return response.lifestreamResponse.data;
});

export const interactionPstnCallOut = createAsyncThunk('interactionCallOut/pstn', async (pstnCallParam: any) => {
  const response = await callOutInteraction(pstnCallParam.incidentId, 'PSTN_OUT', pstnCallParam.subscriberId);
  const data = response.lifestreamResponse.data;
  return {
    incidentId: data.incidentId,
    interactionId: data.interactionId,
    jwt: data.session?.token
  };
});

const interactionPstnCallOutSlice = createSlice({
  name: 'interactioCallOutPstn',
  initialState,
  reducers: {
    setInteractionDetailsData: (state, action: PayloadAction<InteractionPstnCallOutObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as InteractionPstnCallOutObject;
      state.error = undefined;
    },
    setInteractionDetailsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(interactionPstnCallOut.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionPstnCallOut.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as InteractionPstnCallOutObject;
      state.error = undefined;
    });

    builder.addCase(interactionPstnCallOut.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionDetailsData,
  setInteractionDetailsClear
} = interactionPstnCallOutSlice.actions;

export const interactionPstnCalloutSelector = (state: RootState) => state.interactionPstnCallOut;

export default interactionPstnCallOutSlice.reducer;
