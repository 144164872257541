import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {Images} from '../configs/images';
import {callOutInteraction, getInteractionByIdForSubscriber} from '../api/interactions';
import {formatDate, getDateTimeDifference} from '../utils/dateTimeHelper';
import {sortInteractionNotesData} from '../utils/dataPreparationHelper';
import {UserDetails} from '../components/organisms/CallsTable';
import {DataMedicalInformation} from '../components/organisms/MedicalInformation';
import {UserBasicdata} from '../components/organisms/UserInfoPanel';
import {NoteItem} from '../components/layouts/NotesPanel';
import {acceptInteraction} from '../api/interactions';
import {CONSTANTS} from '../constants/constants';

export interface InteractionChatCallOutObject {
  incidentId: string;
  interactionId: string;
  jwt: string;
  conversationId: string;
}

export interface InteractionChatCallOutObjectState {
  data?: InteractionChatCallOutObject;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionChatCallOutObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const interactionChatCallOut = createAsyncThunk('interactionCallOut/chat', async (pstnCallParam: any) => {
  const response = await callOutInteraction(pstnCallParam.incidentId, CONSTANTS.CALL_OUT_CHANNELS.ChatOut, pstnCallParam.subscriberId);
  const data = response.lifestreamResponse.data;
  return {
    incidentId: data.incidentId,
    interactionId: data.interactionId,
    jwt: data.session?.token?.data,
    conversationId: data.session?.sessionId
  };
});

const interactionChatCallOutSlice = createSlice({
  name: 'interactioCallOutChat',
  initialState,
  reducers: {
    setChatCalloutData: (state, action: PayloadAction<InteractionChatCallOutObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as InteractionChatCallOutObject;
      state.error = undefined;
    },
    setChatCalloutDataClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(interactionChatCallOut.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionChatCallOut.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as InteractionChatCallOutObject;
      state.error = undefined;
    });

    builder.addCase(interactionChatCallOut.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setChatCalloutData,
  setChatCalloutDataClear
} = interactionChatCallOutSlice.actions;

export const interactionChatCalloutSelector = (state: RootState) => state.interactionChatCallOut;

export default interactionChatCallOutSlice.reducer;
