import React, {useState, useEffect} from 'react';
import OT from '@opentok/client';
import {CallerVideoProps, CallerVideoVariant} from './CallerVideoControl.props';
import './CallerVideoControl.styles.css';
import appConfig from '../../configs/appConfig.json';
import {VideoBox} from '../../components/atoms/VideoBox';
import {Text} from '../../components/atoms/Text';
import {Button, ButtonVariant} from '../../components/atoms/Button';
import {CallAddressInfo} from '../../components/molecules/CallAddressInfo';
import {
  MobileCallControl,
  MobileCallControlVariant,
} from '../../components/molecules/MobileCallControl';
import {Colors} from '../../configs/colors';
import {ReactComponent as VideoCameraSlash} from '../../assets/images/video-camera-slash.svg';
import {ReactComponent as PhoneSlash} from '../../assets/images/phone-slash.svg';
import {ReactComponent as VideoCamera} from '../../assets/images/video-camera.svg';
import {ReactComponent as PhoneCall} from '../../assets/images/phone-call.svg';
import {ReactComponent as CircleBlink} from '../../assets/images/circle-blink.svg';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {
  setUserTaskEnd,
  setUserTaskIsMissed,
  userTaskSelector,
} from '../../redux/userTaskSlice';
import {
  setVideoCallPublisher,
  setVideoCallSubscriber,
  setVideoCallConnect,
  setVideoCallVideo,
  setVideoCallHold,
  setVideoCallDeviceVideo,
  setVideoCallDeviceAudio,
  setVideoCallCameraFlip,
  videoCallSelector,
  removeVideoCallSubscriber,
  setVideoCallEnd,
  setVideoCallConnecting,
  receiveOutgoingInteractionByInteractionId,
} from '../../redux/videoCallSlice';
import {initiateDialOutCall, PhoneNumber} from '../../redux/dialOutCallSlice';
import {ReactComponent as PhoneDisconnect} from '../../assets/images/phone-disconnect.svg';
import {CONSTANTS} from '../../constants/constants';
import {getCurrentDate} from '../../utils/dateTimeHelper';
import {Battery} from '../../components/molecules/Battery';
import {CircleImage} from '../../components/molecules/CircleImage';

export const CallerVideoControl: React.FC<CallerVideoProps> = ({
  variant,
  address = '',
  date = '',
  time = '',
  isDeviceControlTextVisible = false,
  emergencyContacts = [],
  mobileNumber = '',
  handleOutgoingCall = () => void {},
  handlePstnCall = () => void {},
  onOutgoingVideoCallAutomaticEnd,
  isVideoButtonDisabled = false,
  userLocation,
  profileImage,
}) => {
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const videoCall = useAppSelector(videoCallSelector);

  const [contactToDial, setContactToDial] = useState<PhoneNumber>({
    countryCode: '',
    number: '',
  });
  const [incomingCallIdleTimeout, setIncomingCallIdleTimeout] =
    useState<NodeJS.Timeout>();
  const [outgoingCallIdleTimeout, setOutgoingCallIdleTimeout] =
    useState<NodeJS.Timeout>();
  const [missedCallTimeout, setMissedCallTimeout] = useState<NodeJS.Timeout>();
  const [timeAcceptCall, setTimeAcceptCall] = useState<string>('');
  const [isConnectSubscriber, setIsConnectSubscriber] =
    useState<boolean>(false);

  const getCurrentBattery = () => {
    let currentBattery = 0;
    if (userLocation && userLocation.pathData.length > 0) {
      const lastEntry =
        userLocation?.pathData[userLocation.pathData.length - 1];
      if (lastEntry) {
        currentBattery = lastEntry.deviceBatteryLevel || 0;
      }
    }
    return currentBattery;
  };

  // Handling all of our errors here by alerting them
  const handleError = (error?: OT.OTError | undefined) => {
    if (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (videoCall.session) {
      videoCall.session.on({
        // session connected
        sessionConnected: (event: OT.Event<'sessionConnected', OT.Session>) => {
          console.log('Session Connected:', event);
          const publisherOptions: OT.PublisherProperties = {
            insertMode: 'replace',
            height: '160px',
            width: '120px',
            style: {
              buttonDisplayMode: 'off',
            },
          };
          const publisher = OT.initPublisher(
            'publisher',
            publisherOptions,
            handleError
          );
          dispatch(setVideoCallPublisher(publisher));
          setTimeAcceptCall(getCurrentDate());
        },

        // session reconnecting
        sessionReconnecting: (
          event: OT.Event<'sessionReconnecting', OT.Session>
        ) => {
          console.log('Session reconnecting:', event);
          setIsConnectSubscriber(true);
        },

        // session reconnected
        sessionReconnected: (
          event: OT.Event<'sessionReconnected', OT.Session>
        ) => {
          console.log('Session reconnected:', event);
        },

        // session disconnected
        sessionDisconnected: (
          event: OT.Event<'sessionDisconnected', OT.Session> & {
            reason: string;
          }
        ) => {
          console.log('Session reconnected:', event.reason);
        },

        //connection destroyed
        connectionDestroyed: (
          event: OT.Event<'connectionDestroyed', OT.Session> & {
            connection: OT.Connection;
            reason: string;
          }
        ) => {
          console.log('connection Destroyed:', event.reason);
        },
        streamCreated: (event: any) => {
          console.log('streamCreated ================> 1');

          // The main subscriber gets the full screen view
          const mainSubscriberOption: OT.SubscriberProperties = {
            insertMode: 'replace',
            width: '100%',
            height: '100%',
          };

          //Here we move the other callers to the top right corner
          const subscriberOptions: OT.SubscriberProperties = {
            insertMode: 'replace',
            height: '160px',
            width: '120px',
            style: {
              buttonDisplayMode: 'off',
            },
          };

          dispatch(
            setVideoCallSubscriber({
              mainCallerProperties: mainSubscriberOption,
              secondCallerProperties: subscriberOptions,
              stream: event.stream,
            })
          );
        },
        streamDestroyed: (event: any) => {
          console.log(
            `Stream ${event.stream.name} ended because ${event.reason}.`
          );
          dispatch(removeVideoCallSubscriber(event.stream.streamId));
          setIsConnectSubscriber(false);
        },
        'signal:INF_USER_OFF_SIM_CALL': (event: OT.Event<string, any>) => {
          console.log('signal:INF_USER_OFF_SIM_CALL', event);
        },
      });
      dispatch(setVideoCallConnect());
    }
  }, [videoCall.session]);

  useEffect(() => {
    if (videoCall.publisher) {
      videoCall.publisher.on('streamCreated', function (event) {
        console.log('publisher streamCreated', event);
        if (videoCall.publisher?.element) {
          document
            .getElementById('publisher')
            ?.replaceWith(videoCall.publisher.element);
        }
      });
    }
  }, [videoCall.publisher]);

  useEffect(() => {
    if (videoCall.subscriber) {
      videoCall.subscriber.on({
        // subscriber connected
        connected: (event: OT.Event<'connected', OT.Subscriber>) => {
          console.log('subscriber connected', event);
          if (missedCallTimeout) {
            clearTimeout(missedCallTimeout);
            setMissedCallTimeout(undefined);
          }
          dispatch(setVideoCallConnecting({isLoading: false}));
          setIsConnectSubscriber(false);
          if (event.target.element) {
            document
              .getElementById('subscriber')
              ?.replaceWith(event.target.element);
          }
        },
        // video enabled
        videoEnabled: (
          event: OT.Event<'videoEnabled', OT.Subscriber> & {
            reason: string;
          }
        ) => {
          console.log('subscriber videoEnabled', event.reason);
          setIsConnectSubscriber(false);
        },
        // video disabled
        videoDisabled: (
          event: OT.Event<'videoDisabled', OT.Subscriber> & {
            reason: string;
          }
        ) => {
          console.log('subscriber videoDisabled', event.reason);
          if (event.reason === 'quality') {
            setIsConnectSubscriber(true);
          }
        },
        // stream destroyed
        destroyed: (
          event: OT.Event<'destroyed', OT.Subscriber> & {
            reason: string;
          }
        ) => {
          console.log('subscriber destroyed', event.reason);
          setIsConnectSubscriber(true);
        },
      });
    }
  }, [videoCall.subscriber]);

  // Check missed call

  useEffect(() => {
    if (videoCall.session) {
      const isOutgoing =
        (userTask.attributes &&
          userTask.attributes.channel &&
          userTask.attributes.channel.value ===
            CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut) ||
        false;
      const missedCallDuration =
        +appConfig.Interactions.automaticShowMissedCall * 1000;
      videoCall.session.on({
        sessionConnected: () => {
          console.log('Start check missed call');
          const missedTimeout = setTimeout(() => {
            if (userTask.isConnected && !isOutgoing) {
              dispatch(setUserTaskIsMissed());
            }
          }, missedCallDuration);
          setMissedCallTimeout(missedTimeout);
        },
      });
    }
  }, [userTask.isConnected, videoCall.session]);

  useEffect(() => {
    if (videoCall.publisher?.element) {
      setTimeout(() => {
        console.log('=================> publisher rendered  ==============>');
        document
          .getElementById('publisher')
          ?.replaceWith(videoCall.publisher!.element!);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (videoCall.subscriber?.element) {
      setTimeout(() => {
        console.log('=================> subscriber rendered  ==============>');
        document
          .getElementById('subscriber')
          ?.replaceWith(videoCall.subscriber!.element!);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (contactToDial.number) {
      dispatch(
        initiateDialOutCall({
          sessionId: videoCall.session?.sessionId,
          numberToDial: contactToDial,
        })
      );
    }
  }, [contactToDial]);

  useEffect(() => {
    if (userTask.isConnected) {
      const isOutgoing =
        (userTask.attributes &&
          userTask.attributes.channel &&
          userTask.attributes.channel.value ===
            CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut) ||
        false;
      if (videoCall.additionalSubscribers) {
        if (isOutgoing) {
          if (outgoingCallIdleTimeout) {
            clearTimeout(outgoingCallIdleTimeout);
            setOutgoingCallIdleTimeout(undefined);
          }

          if (
            videoCall.additionalSubscribers.length == 1 &&
            userTask.attributes &&
            userTask.attributes.interactionId
          ) {
            dispatch(
              receiveOutgoingInteractionByInteractionId(
                userTask.attributes.interactionId.value
              )
            );
          }
        }

        if (videoCall.additionalSubscribers.length == 0) {
          const incomingCallEndDuration =
            +appConfig.Interactions.automaticIncomingCallEndDuration * 1000;
          const idleTimeout = setTimeout(() => {
            dispatch(setUserTaskEnd());
            dispatch(setVideoCallEnd());
          }, incomingCallEndDuration);
          setIncomingCallIdleTimeout(idleTimeout);
        } else {
          if (incomingCallIdleTimeout) {
            clearTimeout(incomingCallIdleTimeout);
            setIncomingCallIdleTimeout(undefined);
          }
        }
      } else {
        if (isOutgoing) {
          const outgoingCallEndDuration =
            +appConfig.Interactions.automaticOutgoingCallEndDuration * 1000;
          const idleTimeout = setTimeout(() => {
            onOutgoingVideoCallAutomaticEnd &&
              onOutgoingVideoCallAutomaticEnd();
            dispatch(setUserTaskEnd());
            dispatch(setVideoCallEnd());
            dispatch(setVideoCallConnecting({isLoading: false, isLoadingOutgoing: false}));
          }, outgoingCallEndDuration);
          setOutgoingCallIdleTimeout(idleTimeout);
        }
      }
    }
  }, [videoCall.additionalSubscribers, userTask.isConnected]);

  const handleOnEnded = () => {
    dispatch(setUserTaskEnd());
    dispatch(setVideoCallEnd());
  };

  const handleOnHold = () => {
    dispatch(setVideoCallHold(!videoCall.isHold));
  };

  const handleOnVideo = () => {
    dispatch(setVideoCallVideo(!videoCall.isVideo));
  };

  const handleOnSpeaker = () => {
    // subscriber?.subscribeToAudio(!isAudio);
    // setIsAudio(!isAudio);
  };

  const handleOnAdd = (number?: PhoneNumber) => {
    if (number) {
      setContactToDial(number);
    }
  };

  const handleOnCameraFlip = () => {
    dispatch(setVideoCallCameraFlip());
  };

  const handleOnDeviceVideo = () => {
    dispatch(setVideoCallDeviceVideo(!videoCall.isDeviceVideo));
  };

  const handleOnDeviceMute = () => {
    dispatch(setVideoCallDeviceAudio(!videoCall.isDeviceAudio));
  };

  const handleOnShowDevice = (value: boolean) => {
    const elm = document.querySelector('.mobile-call-info-user-info');
    if (elm) {
      if (value === true && elm.children.length > 0) {
        elm.classList.add('change-height');
      } else {
        elm.classList.remove('change-height');
      }
    }
  };

  const getMobileCallControlVariant = () => {
    switch (variant) {
    case CallerVideoVariant.ActiveWith1Video:
      return MobileCallControlVariant.VideoCallDeviceControl;
    case CallerVideoVariant.ActiveWith2Videos:
      return MobileCallControlVariant.VideoCallDeviceControl;
    default:
      return MobileCallControlVariant.VideoCall;
    }
  };

  useEffect(() => {
    if (userTask.isEnded) {
      if (outgoingCallIdleTimeout) {
        clearTimeout(outgoingCallIdleTimeout);
      }
      if (incomingCallIdleTimeout) {
        clearTimeout(incomingCallIdleTimeout);
      }
    }
  }, [userTask.isEnded]);
  return (
    <div
      className="video-call-wrap"
      onContextMenu={(event) => event.preventDefault()}
    >
      {!videoCall.isLoading &&
        (variant === CallerVideoVariant.ActiveWith1Video ||
          variant === CallerVideoVariant.ActiveWith2Videos) && (
        <div className="video-call-container">
          <div className="publisher-container">
            <VideoBox componentId="publisher" />
          </div>
          <div className="subscriber-container">
            <div id="subscriber" />
            {isConnectSubscriber ? <div className="loader"></div> : null}
          </div>
          {videoCall.additionalSubscribers && (
            <div
              className="merged-call2-wrap"
              style={
                videoCall.additionalSubscribers.length <= 1
                  ? {display: 'none'}
                  : {}
              }
            >
              <VideoBox componentId="additionalSubscriber" />
              <div className="video-additional-call-end-button">
                <Button
                  height="50px"
                  width="50px"
                  variant={ButtonVariant.Icon}
                  onClick={() => undefined}
                  textColor={Colors.LSWhite}
                  color={Colors.LavaRed}
                >
                  <PhoneDisconnect
                    stroke={Colors.LSWhite}
                    width={32}
                    height={32}
                  />
                </Button>
              </div>
            </div>
          )}

          {variant === CallerVideoVariant.ActiveWith2Videos && (
            <div className="merged-call2-wrap">
              <VideoBox componentId="publisher"></VideoBox>
            </div>
          )}
          {variant === CallerVideoVariant.ActiveWith1Video && (
            <>
              <CallAddressInfo
                address={address}
                date={date}
                time={time}
                startTime={userTask.isConnected ? timeAcceptCall : ''}
              />
              <Battery currentBattery={getCurrentBattery()} />
            </>
          )}
          {!videoCall.isVideo && (
            <div className="camera-turn-off">
              <div className="circle-blink">
                <CircleBlink width={200} height={200} />
              </div>
              <CircleImage url={profileImage} size="100px" border="none" />
            </div>
          )}

          <MobileCallControl
            variant={getMobileCallControlVariant()}
            isHold={videoCall.isHold}
            isCamera={videoCall.isVideo}
            isDeviceCamera={videoCall.isDeviceVideo}
            isDeviceMic={videoCall.isDeviceAudio}
            isDeviceControlTextVisible={isDeviceControlTextVisible}
            onEndClick={handleOnEnded}
            onHoldClick={handleOnHold}
            onAddClick={handleOnAdd}
            onVideoClick={handleOnVideo}
            onCameraFlipClick={handleOnCameraFlip}
            onDeviceVideoClick={handleOnDeviceVideo}
            onMicrophoneClick={handleOnDeviceMute}
            onSpeakerClick={handleOnSpeaker}
            onShowDevice={handleOnShowDevice}
            emergencyContacts={emergencyContacts}
          />
        </div>
      )}
      {(videoCall.isLoading || videoCall.isLoadingOutgoing) &&
        (variant === CallerVideoVariant.ActiveWith1Video ||
          variant === CallerVideoVariant.ActiveWith2Videos) && (
        <div className="video-connecting-panel">
          <VideoCameraSlash
            stroke={Colors.LSTealAccent}
            width={64}
            height={64}
          ></VideoCameraSlash>
          <Text
            color={Colors.LSWhite}
            margin="5px"
            fontSize={24}
            fontWeight="400"
          >
              Connecting
          </Text>
          <div className="loader"></div>
        </div>
      )}

      {variant === CallerVideoVariant.NoVideo && (
        <div className="mobile-call-no-video-info">
          <div className="loader"></div>
        </div>
      )}

      {variant === CallerVideoVariant.HungUp && (
        <div className="mobile-call-hung-up-info">
          <PhoneSlash
            stroke={Colors.RCCriticalBG}
            width={64}
            height={64}
          ></PhoneSlash>
          <Text
            color={Colors.LSWhite}
            margin="5px"
            fontSize={24}
            fontWeight="400"
            lineHeight="28px"
          >
            {'The caller has hung up,'}
          </Text>
          <Text
            color={Colors.LSWhite}
            margin="5px"
            fontSize={24}
            fontWeight="400"
            lineHeight="28px"
          >
            {'call back immediately.'}
          </Text>
          <Text
            color={Colors.LSWhite}
            fontSize={15}
            fontWeight="400"
            margin="8px"
          >
            If no answer, no further action is required.
          </Text>
          <div className="hung-up-options">
            <div className="hung-up-options-button">
              <Button
                variant={ButtonVariant.Icon}
                onClick={() => handleOutgoingCall()}
                color={Colors.RCSuccess}
                margin="5px"
                height="50px"
                width="50px"
                disabled={isVideoButtonDisabled}
              >
                <VideoCamera stroke={Colors.LSWhite} width={32} height={32} />
              </Button>
              <Text color={Colors.LSWhite} fontSize={12}>
                Video
              </Text>
            </div>
            <div className="hung-up-options-button">
              <Button
                variant={ButtonVariant.Icon}
                onClick={() => handlePstnCall(mobileNumber)}
                color={Colors.RCSuccess}
                margin="5px"
                height="50px"
                width="50px"
              >
                <PhoneCall stroke={Colors.LSWhite} width={32} height={32} />
              </Button>
              <Text color={Colors.LSWhite} fontSize={12}>
                Mobile
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
