import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {IncidentInteractionHistory, InteractionHistory} from '../pages/Caller';
import {getInteractionsHistory} from '../api/interactions';
import {formatDate, getTimeDuration} from '../utils/dateTimeHelper';
import {getInteractions, sortIncidentData, sortInteractionHistoryData, sortInteractionNotesData} from '../utils/dataPreparationHelper';

export interface InteractionHistoryObjectState {
  data?: IncidentInteractionHistory[];
  isLoading: boolean;
  error?: string;
  isInteractionUpdateAvailable: boolean;
}

const initialState: InteractionHistoryObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  isInteractionUpdateAvailable: false
};

export const interactionHistoryBySubscriberId = createAsyncThunk('interactionHistory/BySubscriberId', async (subscriberId: string) => {
  const response = await getInteractionsHistory(subscriberId);
  const data = response.lifestreamResponse.data;
  return data ? sortIncidentData(data.map((incident: any) => {
    return {
      incidentId: incident.incidentId,
      incidentStatus: incident.incidentStatus,
      companyId: incident.companyId,
      createdDate: incident.createdDate,
      interactions: sortInteractionHistoryData(getInteractions(incident.interactions, incident.incidentId), 'asc'),
    };
  }),'desc') : [];
});

const interactionHistorySlice = createSlice({
  name: 'interactionHistory',
  initialState,
  reducers: {
    setInteractionHistoryData: (state, action: PayloadAction<IncidentInteractionHistory[] | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as IncidentInteractionHistory[];
      state.error = undefined;
      state.isInteractionUpdateAvailable = false;
    },
    setInteractionHistoryClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
      state.isInteractionUpdateAvailable = false;
    },
    setInteractionUpdateStatus: (state, action: PayloadAction<boolean>) => {
      state.isInteractionUpdateAvailable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(interactionHistoryBySubscriberId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionHistoryBySubscriberId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as IncidentInteractionHistory[];
      state.error = undefined;
      state.isInteractionUpdateAvailable = false;
    });

    builder.addCase(interactionHistoryBySubscriberId.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
      state.isInteractionUpdateAvailable = false;
    });
  },
});

export const {
  setInteractionHistoryData,
  setInteractionHistoryClear,
  setInteractionUpdateStatus,
} = interactionHistorySlice.actions;

export const interactionHistorySelector = (state: RootState) => state.interactionHistory;

export default interactionHistorySlice.reducer;
